<template>
  <div class="slds-box slds-box_x-small slds-theme_default">
    <nav role="navigation" aria-label="Breadcrumbs">
      <ol class="slds-breadcrumb slds-p-left_xx-small slds-list_horizontal slds-wrap">
        <li class="slds-breadcrumb__item" v-for="crumb in crumbs" :key="crumb.text">
          <template v-if="crumb.disabled">
            <a class="slds-text-link_reset">{{ crumb.text }}</a>
          </template>
          <template v-else>
            <router-link :to="crumb.to">{{ crumb.text }}</router-link>
          </template>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const validRoutes = [
  'snapshots',
  'snapshotScans',
  'connectionSnapshots',
  'snapshot',
  'scans',
  'scan',
  'items',
  'pmds',
  'eslints',
  'item',
  'releaseUpdates',
  'orgLimitStatuses',
  'objectFieldCounts',
  'objectAutomations',
  'securityHealthCheckRisks',
  'licenseUsages',
  'roles',
  'sfProfiles',
  'reports',
  'reportTypes',
  'permissionSets',
  'groups',
  'installedPackages',
  'sharingRules',
  'objectApprovalProcesses',
  'testCoverage',
  'testCoverageItem',
];

export default {
  computed: {
    crumbs() {
      const result = [];
      const routeName = this.$route.name;
      const recordData = this.getRecordDataForRouteName(routeName);

      if (routeName === 'snapshots') {
        result.push({
          text: 'Snapshots',
          disabled: true,
        });
        return result;
      }

      if (validRoutes.includes(routeName)) {
        result.push({
          to: { name: 'snapshots' },
          text: 'Snapshots',
        });
      }

      if (routeName === 'connectionSnapshots') {
        result.push({
          text: 'Connection',
          disabled: true,
        });
        return result;
      }

      if (routeName === 'snapshot') {
        result.push({
          text: recordData.snapshotName,
          disabled: true,
        });
        return result;
      }

      if (validRoutes.includes(routeName) && recordData.snapshotId) {
        result.push({
          to: { name: 'snapshot', params: { id: recordData.snapshotId } },
          text: recordData.snapshotName,
        });
      }

      if (routeName === 'scans' || routeName === 'snapshotScans') {
        result.push({
          text: 'Scans',
          disabled: true,
        });
        return result;
      }

      if (routeName === 'scan' && recordData.scanId) {
        result.push({
          text: `Scan (${recordData.scanId})`,
          disabled: true,
        });

        return result;
      }

      if (validRoutes.includes(routeName) && recordData.scanId) {
        result.push({
          to: { name: 'scan', params: { id: recordData.scanId } },
          text: `Scan (${recordData.scanName})`,
        });
      }

      if (routeName === 'items' || routeName === 'pmds') {
        result.push({
          text: routeName === 'items' ? 'Items' : 'PMDs',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'eslints') {
        result.push({
          text: 'ES-Lints',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'testCoverage') {
        result.push({
          text: 'Test Coverage',
          disabled: true,
        });

        return result;
      }
      if (routeName === 'testCoverageItem') {
        result.push({
          text: 'Test Coverage Detail',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'objectApprovalProcesses') {
        result.push({
          text: 'Approval Processes',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'objectFieldCounts') {
        result.push({
          text: 'Object Field Counts',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'objectAutomations') {
        result.push({
          text: 'Object Automations',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'releaseUpdates') {
        result.push({
          text: 'Release Updates',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'licenseUsages') {
        result.push({
          text: 'License Usages',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'securityHealthCheckRisks') {
        result.push({
          text: 'Security Health Check Risks',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'orgLimitStatuses') {
        result.push({
          text: 'Org Limits',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'installedPackages') {
        result.push({
          text: 'Installed Packages',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'roles') {
        result.push({
          text: 'Roles',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'sfProfiles') {
        result.push({
          text: 'SfProfiles',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'permissionSets') {
        result.push({
          text: 'PermissionSets',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'reports') {
        result.push({
          text: 'Reports',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'reportTypes') {
        result.push({
          text: 'Report Types',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'groups') {
        result.push({
          text: 'Groups',
          disabled: true,
        });

        return result;
      }

      if (routeName === 'sharingRules') {
        result.push({
          text: 'SharingRules',
          disabled: true,
        });

        return result;
      }

      if (validRoutes.includes(routeName) && recordData.scanId) {
        result.push({
          to: {
            name: 'items',
            params: { id: recordData.scanId },
            query: this.$route.query,
          },
          text: 'Items',
        });
      }

      if (routeName === 'item') {
        result.push({
          text: recordData.itemName,
          disabled: true,
        });
      }

      return result;
    },
    ...mapState({
      connection: state => state.sfConnections.current,
      snapshot: state => state.snapshots.current,
      scan: state => state.scans.current,
      item: state => state.items.current,
    }),
  },
  methods: {
    getRecordDataForRouteName(routeName) {
      let result = {
        snapshotId: null,
        snapshotName: null,
        connectionId: null,
        connectionName: null,
        scanId: null,
        scanName: null,
        itemId: null,
        itemName: null,
      };
      if (routeName === 'connectionSnapshots' && this.connection) {
        result = {
          connectionId: this.connection.id.toString(),
          connectionName: this.connection.name,
        };
      }
      if ((routeName === 'snapshot' || routeName === 'scans' || routeName === 'snapshotScans') && this.snapshot) {
        result = {
          snapshotId: this.snapshot.id.toString(),
          snapshotName: this.snapshot.name,
        };
      } else if (
        (routeName === 'scan' ||
          routeName === 'items' ||
          routeName === 'pmds' ||
          routeName === 'eslints' ||
          routeName === 'releaseUpdates' ||
          routeName === 'orgLimitStatuses' ||
          routeName === 'objectFieldCounts' ||
          routeName === 'objectAutomations' ||
          routeName === 'roles' ||
          routeName === 'sfProfiles' ||
          routeName === 'reports' ||
          routeName === 'reportTypes' ||
          routeName === 'permissionSets' ||
          routeName === 'groups' ||
          routeName === 'securityHealthCheckRisks' ||
          routeName === 'licenseUsages' ||
          routeName === 'installedPackages' ||
          routeName === 'sharingRules' ||
          routeName === 'objectApprovalProcesses' ||
          routeName === 'testCoverage' ||
          routeName === 'testCoverageItem') &&
        this.scan
      ) {
        result = {
          snapshotId: this.scan.metadataId.toString(),
          snapshotName: this.scan.metadataName,
          scanId: this.scan.id.toString(),
          scanName: this.scan.name,
        };
      } else if (routeName === 'item' && this.item) {
        result = {
          snapshotId: this.item.metadataId.toString(),
          snapshotName: this.item.metadataName,
          scanId: this.item.scanId.toString(),
          scanName: this.item.scanName,
          itemId: this.item.id.toString(),
          itemName: this.item.name,
        };
      }

      return result;
    },
  },
};
</script>
