import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { authGuard } from '@/auth/authGuard';

import Snapshots from '../views/Snapshots';
import ConnectionSnapshots from '../views/ConnectionSnapshots';
import Snapshot from '../views/Snapshot';
import Scans from '../views/Scans';
import Scan from '../views/Scan';
import ReleaseUpdates from '../views/ReleaseUpdates';
import OrgLimitStatuses from '../views/OrgLimitStatuses';
import ObjectFieldCounts from '../views/ObjectFieldCounts';
import ObjectAutomations from '../views/ObjectAutomations';
import ObjectApprovalProcesses from '../views/ObjectApprovalProcesses';
import LicenseUsages from '../views/LicenseUsages';
import InstalledPackages from '../views/InstalledPackages';
import SecurityHealthCheckRisks from '../views/SecurityHealthCheckRisks';
import Roles from '../views/Roles';
import SfProfiles from '../views/SfProfiles';
import ReportTypes from '../views/ReportTypes';
import Reports from '../views/Reports';
import PermissionSets from '../views/PermissionSets';
import Groups from '../views/Groups';
import SharingRules from '../views/SharingRules';
import Items from '../views/Items';
import Item from '../views/Item';
import Pmds from '../views/Pmds';
import ESLints from '../views/ESLints';
import NoAccess from '../views/NoAccess.vue';
import SalesforceCallback from '../views/SalesforceCallback.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/scans',
  },
  {
    path: '/callback',
    name: 'salesforceCallback',
    component: SalesforceCallback,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/snapshots',
    name: 'snapshots',
    component: Snapshots,
    meta: {
      breadCrumb: 'Snapshots',
      requiresAuth: true,
    },
  },
  {
    path: '/connectionSnapshots/:id',
    name: 'connectionSnapshots',
    component: ConnectionSnapshots,
    props: true,
    meta: {
      breadCrumb: 'Connection Snapshots',
      requiresAuth: true,
    },
  },
  {
    path: '/snapshots/:id',
    name: 'snapshot',
    component: Snapshot,
    props: true,
    meta: {
      breadCrumb: 'Snapshot',
      requiresAuth: true,
    },
  },
  {
    path: '/snapshots/:id/scans',
    name: 'snapshotScans',
    component: Scans,
    props: true,
    meta: {
      breadCrumb: 'Scans',
      requiresAuth: true,
    },
  },
  {
    path: '/scans',
    name: 'scans',
    component: Scans,
    meta: {
      breadCrumb: 'Scans',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id',
    name: 'scan',
    component: Scan,
    props: true,
    meta: {
      breadCrumb: 'Scan',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/object-field-counts',
    name: 'objectFieldCounts',
    component: ObjectFieldCounts,
    props: true,
    meta: {
      breadCrumb: 'Object Field Counts',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/object-automations',
    name: 'objectAutomations',
    component: ObjectAutomations,
    props: true,
    meta: {
      breadCrumb: 'Object Automations',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/object-approval-processes',
    name: 'objectApprovalProcesses',
    component: ObjectApprovalProcesses,
    props: true,
    meta: {
      breadCrumb: 'Object Approval Processes',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/release-updates',
    name: 'releaseUpdates',
    component: ReleaseUpdates,
    props: true,
    meta: {
      breadCrumb: 'Release Updates',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/org-limit-statuses',
    name: 'orgLimitStatuses',
    component: OrgLimitStatuses,
    props: true,
    meta: {
      breadCrumb: 'Org Limits',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/license-usages',
    name: 'licenseUsages',
    component: LicenseUsages,
    props: true,
    meta: {
      breadCrumb: 'License Usages',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/installed-packages',
    name: 'installedPackages',
    component: InstalledPackages,
    props: true,
    meta: {
      breadCrumb: 'Installed Packages',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/roles',
    name: 'roles',
    component: Roles,
    props: true,
    meta: {
      breadCrumb: 'Roles',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/sfProfiles',
    name: 'sfProfiles',
    component: SfProfiles,
    props: true,
    meta: {
      breadCrumb: 'SfProfiles',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/report-types',
    name: 'reportTypes',
    component: ReportTypes,
    props: true,
    meta: {
      breadCrumb: 'Report Types',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/reports',
    name: 'reports',
    component: Reports,
    props: true,
    meta: {
      breadCrumb: 'Reports',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/permissionSets',
    name: 'permissionSets',
    component: PermissionSets,
    props: true,
    meta: {
      breadCrumb: 'PermissionSets',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/groups',
    name: 'groups',
    component: Groups,
    props: true,
    meta: {
      breadCrumb: 'Groups',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/sharing-rules',
    name: 'sharingRules',
    component: SharingRules,
    props: true,
    meta: {
      breadCrumb: 'SharingRules',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/security-Health-Risks',
    name: 'securityHealthCheckRisks',
    component: SecurityHealthCheckRisks,
    props: true,
    meta: {
      breadCrumb: 'Security Health Risks',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/items',
    name: 'items',
    component: Items,
    props: true,
    meta: {
      breadCrumb: 'Items',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/pmds',
    name: 'pmds',
    component: Pmds,
    props: true,
    meta: {
      breadCrumb: 'Pmds',
      requiresAuth: true,
    },
  },
  {
    path: '/scans/:id/eslints',
    name: 'eslints',
    component: ESLints,
    props: true,
    meta: {
      breadCrumb: 'ESLints',
      requiresAuth: true,
    },
  },
  {
    path: '/items/:id',
    name: 'item',
    component: Item,
    props: true,
    meta: {
      breadCrumb: 'Item',
      requiresAuth: true,
    },
  },
  {
    path: '/noaccess',
    name: 'noaccess',
    component: NoAccess,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(
  multiguard([
    (to, from, next) => {
      authGuard(to, from, next);
    },
    (to, from, next) => {
      window.scrollTo(0, 0);
      next();
    },
  ])
);

export default router;
