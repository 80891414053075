import api from '../../utils/api';

const state = () => ({
  all: [],
  current: null,
});

const getters = {};

const actions = {
  async getAll({ commit }) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const snapshots = await api.get({ path: '/metadatas' });
      commit('setAll', snapshots);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async get({ commit }, id) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const snapshot = await api.get({ path: `/metadatas/${id}` });
      commit('setCurrent', snapshot);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async rename({ commit, dispatch }, { id, name }) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const message = await api.put({
        path: `/metadatas/${id}`,
        body: { name },
      });
      // Todo: we need to fix error handling
      if (message.message === "Can't rename metadata in 'In Progress' status") {
        this._vm.$notify({
          group: 'notifications',
          type: 'error',
          title: `Rename`,
          text: message.message,
        });
      } else {
        dispatch('getAll');
        this._vm.$notify({
          group: 'notifications',
          type: 'success',
          title: `Rename`,
          text: 'Snapshot is renamed successfully!',
        });
      }
    } catch (error) {
      console.log(error.response);
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async delete({ commit, dispatch }, { id }) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      await api.delete({
        path: `/metadatas/${id}`,
      });
      dispatch('getAll');
      this._vm.$notify(
        {
          group: 'notifications',
          type: 'success',
          title: `Delete Snapshot`,
          text: 'Snapshot deleted successfully!',
        },
        6000
      );
      commit('app/clearActionInProgress', null, { root: true });
    } catch (error) {
      commit('app/setError', error, { root: true });
      commit('app/clearActionInProgress', null, { root: true });
      throw error;
    }
  },
};

const mutations = {
  setAll(state, all) {
    state.all = all;
  },
  setCurrent(state, current) {
    state.current = current;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
