<template>
  <div class="slds-dropdown-trigger slds-dropdown-trigger_click" :class="{ 'slds-is-open': isOpen }">
    <button
      class="slds-button slds-global-actions__avatar slds-global-actions__item-action"
      title="person name"
      aria-haspopup="true"
      @click="toggleOpen"
      @blur="close"
    >
      <span class="slds-avatar slds-avatar_circle slds-avatar_medium">
        <img alt="Person name" :src="$auth.user.picture" title="User avatar" />
      </span>
    </button>
    <div class="slds-dropdown slds-dropdown_right slds-dropdown_small">
      <ul class="slds-dropdown__list" role="menu" aria-label="Show More">
        <li class="slds-dropdown__header slds-truncate" title="Menu Sub Heading" role="separator">
          <span>{{ $auth.user.name }}</span>
          <br />
          <span class="slds-text-body_small">{{ $auth.user.email }}</span>
        </li>
        <li class="slds-has-divider_top-space" role="separator"></li>
        <li class="slds-dropdown__item" role="presentation">
          <a @mousedown="logout" role="menuitem" tabindex="0">
            <span class="slds-truncate" title="Log Out">Log Out</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
