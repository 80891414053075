<template>
  <form novalidate>
    <Modal :title="'Editing Snapshot ' + id" v-show="show" @close="handleClose" v-if="snapshot">
      <div slot="body">
        <div class="slds-form">
          <InputComponent v-model="form.name" :v="$v.form.name" label="Name" placeholder="Snapshot Name" />
        </div>
      </div>
      <div slot="footer">
        <button class="slds-button slds-button_brand" @click.prevent="submit" :disabled="$v.form.$invalid">Save</button>
        <button type="reset" class="slds-button slds-button_neutral" @click="handleCancel">Cancel</button>
      </div>
    </Modal>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import Modal from './Modal';
import InputComponent from './InputComponent.vue';
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDotValidator', /^[A-Za-z\d\-_\s\\(\\)]+$/);

export default {
  name: 'SnapshotEditor',
  props: {
    snapshot: Object,
    show: { type: Boolean, default: false },
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(80),
        alphaNumAndDotValidator,
        consecutiveWhitespace(value) {
          if (value === '') return true;

          var patt = new RegExp(/\s{2,}/);
          var res = patt.test(value);
          return !res;
        },
      },
    },
  },
  data() {
    return {
      form: {
        name: this.snapshot ? this.snapshot.name : '',
      },
    };
  },
  mounted() {
    if (this.snapshot) {
      this.form.name = this.snapshot.name;
    }
  },
  watch: {
    snapshot() {
      if (this.snapshot) {
        this.form.formname = this.snapshot.name;
      }
    },
    // show() {
    //   if (this.show) {
    //     this.$nextTick(() => {
    //       this.$refs.name.select();
    //     });
    //   }
    // }
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
    }),
    id() {
      return this.snapshot ? this.snapshot.id : null;
    },
    disableSave() {
      return this.actionInProgress || this.snapshot.name === this.name;
    },
  },
  methods: {
    ...mapActions({
      rename: 'snapshots/rename',
    }),
    async submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      await this.rename({ id: this.snapshot.id, name: this.form.name });
      this.handleClose(true);
    },
    async handleSave() {
      await this.rename({ id: this.snapshot.id, name: this.name });
      this.handleClose(true);
    },
    handleCancel() {
      this.handleClose();
    },
    handleClose(saved) {
      this.name = this.snapshot.name;
      this.$emit('close', { saved });
    },
  },
  components: {
    Modal,
    InputComponent,
  },
};
</script>
