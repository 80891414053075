var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{directives:[{name:"columns-resizable",rawName:"v-columns-resizable"}],staticClass:"table slds-table slds-table_bordered slds-table_fixed-layout slds-table_resizable-cols slds-tree slds-table_tree",attrs:{"role":"grid"}},[_c('thead',[_c('tr',{staticClass:"slds-line-height_reset"},[_vm._l((_vm.columnsInternal),function(column){return _c('th',{key:column instanceof Object ? column.field : column,staticClass:"slds-is-resizable",style:(column.field === 'id' ? { width: '4rem' } : column.width ? { width: column.width + 'px' } : ''),attrs:{"scope":"col"}},[_c('a',{staticClass:"slds-th__action slds-text-link_reset",attrs:{"role":"button","tabindex":"-1"}},[_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Sort by:")]),_c('div',{staticClass:"slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate"},[_c('span',{class:{ 'slds-truncate': column.truncate },attrs:{"title":_vm._f("humanize")(column.title)}},[_vm._v(_vm._s(_vm._f("humanize")(column.title)))]),_c('span',{staticClass:"slds-icon_container slds-icon-utility-arrowdown"},[_c('svg',{staticClass:"slds-icon slds-icon-text-default slds-is-sortable__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#arrowdown"}})])]),_c('div',{staticClass:"sort-icon-placeholder"})])]),_vm._m(0,true)])}),(_vm.hasActions)?_c('th',{staticClass:"action-col",attrs:{"scope":"col"}},[_c('div',{staticClass:"slds-truncate slds-assistive-text",attrs:{"title":"Actions"}},[_vm._v("Actions")])]):_vm._e()],2)]),_c('tbody',_vm._l((_vm.tableData),function(entry){return _c('tr',{key:entry.id,staticClass:"slds-hint-parent",attrs:{"aria-expanded":"true","aria-level":entry.level,"aria-posinset":"2","aria-selected":"false","aria-setsize":"2"}},[_vm._l((_vm.columnsInternal),function(column,index){return [_c('td',{key:column.field,class:{
            'slds-cell-wrap': column.wrap,
            'slds-tree__item': index === 1,
          },attrs:{"role":"gridcell","data-label":entry[column.field]}},[(index === 1)?_c('button',{staticClass:"slds-button slds-button_icon slds-button_icon-x-small slds-m-right_x-small",attrs:{"aria-hidden":"true","tabindex":"-1"}},[_c('svg',{staticClass:"slds-button__icon slds-button__icon_small",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#chevronright"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Collapse Acme Corporation")])]):_vm._e(),((column.field === 'id' || column.title === 'id') && _vm.type)?[_c('div',{staticClass:"slds-has-flexi-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry.name || entry[column.field]}},[_c('router-link',{attrs:{"to":{
                  name: _vm.type,
                  params: { id: entry[column.field].toString() },
                  query: _vm.queryString,
                }}},[_vm._v(_vm._s(entry[column.field]))])],1)]:(column.type && column.field && entry[column.field] && column.paramField && column.valueField)?[_c('div',{staticClass:"slds-has-flexi-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.field][column.valueField]}},[_c('router-link',{attrs:{"to":{
                  name: column.type,
                  params: {
                    id: entry[column.field][column.paramField].toString(),
                  },
                  query: _vm.queryString,
                }}},[_vm._v(_vm._s(entry[column.field][column.valueField]))])],1)]:(column.type && column.field && column.paramField && column.valueField)?[_c('div',{staticClass:"slds-has-flexi-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":column.valueField}},[_c('router-link',{attrs:{"to":{
                  name: column.type,
                  params: {
                    id: entry[column.paramField].toString(),
                  },
                  query: _vm.queryString,
                }}},[_vm._v(_vm._s(entry[column.valueField]))])],1)]:[_c('div',{staticClass:"slds-has-flexi-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.field]}},[_vm._v(" "+_vm._s(_vm.showRelatedObject(entry, column))+" ")])]],2)]})],2)}),0),_c('tfoot',[_vm._t("footer")],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slds-resizable"},[_c('input',{staticClass:"slds-resizable__input slds-assistive-text",attrs:{"type":"range","aria-label":"Name column width","max":"1000","min":"20","tabindex":"0"}}),_c('span',{staticClass:"slds-resizable__handle"},[_c('span',{staticClass:"slds-resizable__divider"})])])}]

export { render, staticRenderFns }