export default {
  success: (page, title, message) => {
    page.$notify({
      group: 'notifications',
      type: 'success',
      duration: 6000,
      title: title,
      text: message,
    });
  },
  error: (page, title, message) => {
    page.$notify({
      group: 'notifications',
      type: 'error',
      duration: 6000,
      title: title,
      text: message,
    });
  },
  info: (page, title, message) => {
    page.$notify({
      group: 'notifications',
      type: 'info',
      duration: 6000,
      title: title,
      text: message,
    });
  },
};
