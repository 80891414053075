import axios from 'axios';
import getEnv from '@/utils/getEnv';
import Vue from 'vue';

const XRAY_SERVER_URL = getEnv('VUE_APP_XRAY_SERVER_URL') ? getEnv('VUE_APP_XRAY_SERVER_URL') : 'http://localhost:3000';

const getToken = async () => await Vue.prototype.$auth.getTokenSilently();

export default {
  get: async config => {
    const result = await axios.get(XRAY_SERVER_URL + config.path, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return result.data;
  },

  getFile: async config => {
    const result = await axios.get(XRAY_SERVER_URL + config.path, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
      responseType: 'blob',
    });
    return result.data;
  },

  post: async config => {
    const result = await axios.post(XRAY_SERVER_URL + config.path, config.body, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return result.data;
  },

  put: async config => {
    const result = await axios.put(XRAY_SERVER_URL + config.path, config.body, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return result.data;
  },

  delete: async config => {
    const result = await axios.delete(XRAY_SERVER_URL + config.path, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return result.data;
  },
};
