<template>
  <blockquote
    class="doc site-blockquote"
    v-bind:class="{
      'site-blockquote_note': type === 'note',
      'site-blockquote_warning': type === 'warning',
      'site-blockquote_error': type === 'error',
      'site-blockquote_a11y': type === 'success',
    }"
  >
    <h4 class="lead"><slot name="title" /></h4>
    <div class="doc">
      <slot name="body" />
    </div>
  </blockquote>
</template>
<script>
export default {
  name: 'StaticInformationBox',
  props: {
    type: { type: String, required: false, default: 'note' },
  },
};
</script>
