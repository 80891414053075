<template>
  <div class="slds-align_absolute-center slds-p-vertical_x-small">
    <div class="slds-button-group" role="group">
      <div>
        <button class="slds-button slds-button_neutral slds-button_first" @click="firstPage()" :disabled="isFirstPage">
          First
        </button>
      </div>

      <div>
        <button
          class="slds-button slds-button_middle slds-button_neutral"
          @click="previousPage()"
          :disabled="isFirstPage"
        >
          Previous
        </button>
      </div>

      <div v-for="page in pages" :key="page.id">
        <button
          class="slds-button slds-button_middle slds-button_neutral"
          @click="changePage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isCurrentPage(page.name) }"
        >
          {{ page.name }}
        </button>
      </div>

      <div>
        <button class="slds-button slds-button_middle slds-button_neutral" @click="nextPage()" :disabled="isLastPage">
          Next
        </button>
      </div>

      <div>
        <button class="slds-button slds-button_neutral slds-button_last" @click="lastPage()" :disabled="isLastPage">
          Last
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    items: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: null,
    };
  },
  created() {
    this.firstPage();
  },
  watch: {
    items() {
      this.setValidPageOnDataChange();
    },
    perPage() {
      this.setValidPageOnDataChange();
    },
    currentPage() {
      this.display();
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.items.length / this.perPage);
    },
    startPage() {
      if (this.currentPage === 1 || this.currentPage - 2 <= 1) {
        return 1;
      } else if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      } else if (this.currentPage + 2 >= this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      } else {
        return this.currentPage - 2;
      }
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isFirstPage() {
      return this.currentPage === 1 || this.items.length === 0;
    },
    isLastPage() {
      return this.currentPage === this.totalPages || this.items.length === 0;
    },
  },
  methods: {
    ...mapMutations({ setData: 'dataPaginate/setData' }),
    display() {
      const from = this.currentPage * this.perPage - this.perPage;
      const to = this.currentPage * this.perPage;
      this.setData(this.items.slice(from, to));
    },
    isCurrentPage(page) {
      return this.currentPage === page;
    },
    firstPage() {
      this.currentPage = 1;
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
    },
    changePage(page) {
      this.currentPage = page;
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
    },
    lastPage() {
      this.currentPage = this.totalPages;
    },
    setValidPageOnDataChange() {
      if (this.currentPage === 0) {
        this.firstPage();
      } else if (this.currentPage > this.totalPages) {
        this.lastPage();
      }

      this.display();
    },
  },
};
</script>
