<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-queue" title="Installed Packages">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use
              xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#case_change_status"
            />
          </svg>
          <span class="slds-assistive-text">Installed Packages</span>
        </span>
      </div>
      <div slot="title">
        <span>Installed Packages ({{ installedPackages.length }})</span>
      </div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <DataSearch :items="filteredItems" :columns="columns" />
          </div>
          <div class="slds-page-header__control">
            <DataFilter :items="installedPackages" filter-field="name" title="Filter by is Managed" />
          </div>
        </div>
      </div>
      <div slot="body">
        <DataSort :items="searchedItems" :column="sortColumn" :desc="sortDesc" :columns="columns" />
        <DataTable
          :displayRows="currentPage"
          :columns="columns"
          sortable
          :isAlreadySorted="true"
          :sort-column="sortColumn"
          :sort-desc="sortDesc"
          @sort="handleSort"
        />

        <DataPaginate :per-page="100" :items="sortedItems" />
      </div>
    </DetailCard>
  </div>
</template>

<script>
import DetailCard from '../components/DetailCard';
import DataFilter from '../components/DataFilter';
import DataSearch from '../components/DataSearch';
import DataTable from '../components/DataTable';
import DataSort from '../components/DataSort';
import DataPaginate from '../components/DataPaginate';
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      columns: [
        { title: 'Id', field: 'id' },
        {
          title: 'Name',
          field: 'name',
          width: 200,
          wrap: true,
        },
        { title: 'description', field: 'description', width: 200, wrap: true },
        { title: 'publisherName', field: 'publisherName', wrap: true },
        { title: 'releaseState', field: 'releaseState' },
        {
          title: 'namespacePrefix',
          field: 'namespacePrefix',
          wrap: true,
        },
        {
          title: 'majorVersion',
          field: 'majorVersion',
        },
        {
          title: 'minorVersion',
          field: 'minorVersion',
        },
        {
          title: 'patchVersion',
          field: 'patchVersion',
        },
        {
          title: 'isDeprecated',
          field: 'isDeprecated',
        },
        {
          title: 'isManaged',
          field: 'isManaged',
        },
        {
          title: 'isPasswordProtected',
          field: 'isPasswordProtected',
        },
        {
          title: 'isSecurityReviewed',
          field: 'isSecurityReviewed',
        },
      ],
      sortColumn: 'id',
      sortDesc: false,
      currentRecord: null,
      type: 'InstalledPackage',
    };
  },
  async mounted() {
    await this.getScan({ id: this.id });
    await this.getAll(this.id);
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      installedPackages: state => state.installedPackages.all,
      filteredItems: state => state.dataFilter.data,
      searchedItems: state => state.dataSearch.data,
      sortedItems: state => state.dataSort.data,
      currentPage: state => state.dataPaginate.data,
    }),
    data() {
      return this.installedPackages;
    },
  },
  methods: {
    ...mapActions({
      getScan: 'scans/get',
    }),
    ...mapCacheActions('installedPackages', ['getAll']),
    handleSort(event) {
      if (event.column.field === this.sortColumn) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = event.column.field;
        this.sortDesc = false;
      }
    },
  },
  components: {
    DataFilter,
    DataSearch,
    DataSort,
    DataTable,
    DataPaginate,
    DetailCard,
  },
};
</script>
<style scoped>
.setup_release_updateReleaseUpdateStatus {
  border: 2px solid rgb(217, 219, 221);
  background-color: #ffffff;
}
.setup_release_updateReleaseUpdateStatus.background-OVERDUE {
  background: rgb(255, 151, 147);
  border: 2px solid rgb(194, 57, 52);
}
.setup_release_updateReleaseUpdateStatus .badge-CRITICAL {
  background-color: rgb(194, 57, 52);
  color: rgb(255, 255, 255);
}
.setup_release_updateReleaseUpdateStatus .slds-icon_container {
  color: rgb(255, 255, 255);
}
</style>
