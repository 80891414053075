var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{directives:[{name:"columns-resizable",rawName:"v-columns-resizable"}],staticClass:"table slds-table slds-table_bordered slds-table_fixed-layout slds-table_resizable-cols",attrs:{"role":"grid"}},[_c('thead',[_c('tr',{staticClass:"slds-line-height_reset"},[(_vm.sortable)?_vm._l((_vm.columnsInternal),function(column){return _c('th',{key:column instanceof Object ? column.field : column,staticClass:"initialSortAsc sortable slds-is-resizable slds-is-sortable",class:{
            'slds-is-sorted': _vm.innerSortColumn === column,
            'slds-is-sorted_asc': _vm.innerSortColumn === column && !_vm.innerSortDesc,
            'slds-is-sorted_desc': _vm.innerSortColumn === column && _vm.innerSortDesc,
          },style:(column.field === 'id' ? { width: '4rem' } : column.width ? { width: column.width + 'px' } : ''),attrs:{"scope":"col"}},[_c('a',{staticClass:"slds-th__action slds-text-link_reset",attrs:{"role":"button","tabindex":"-1"},on:{"click":function($event){return _vm.sortBy(column)}}},[_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Sort by:")]),_c('div',{staticClass:"slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate"},[_c('span',{class:{ 'slds-truncate': column.truncate },attrs:{"title":_vm._f("humanize")(column.title)}},[_vm._v(_vm._s(_vm._f("humanize")(column.title)))]),_c('span',{staticClass:"slds-icon_container slds-icon-utility-arrowdown"},[_c('svg',{staticClass:"slds-icon slds-icon-text-default slds-is-sortable__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#arrowdown"}})])]),_c('div',{staticClass:"sort-icon-placeholder"})])]),_vm._m(0,true),_c('span',{staticClass:"slds-assistive-text",attrs:{"v-if":_vm.sortColumn === column,"aria-live":"assertive","aria-atomic":"true"}},[_vm._v("Sorted "+_vm._s(_vm.sortDesc ? 'descending' : 'ascending'))])])}):_vm._l((_vm.columnsInternal),function(column){return _c('th',{key:column.field,staticClass:"slds-is-resizable",attrs:{"scope":"col"}},[_c('div',{class:{ 'slds-truncate': column.truncate },attrs:{"title":column.title}},[_vm._v(" "+_vm._s(column.title)+" ")])])}),(_vm.showActions)?_c('th',{staticClass:"action-col",attrs:{"scope":"col"}},[_c('div',{staticClass:"slds-truncate slds-assistive-text",attrs:{"title":"Actions"}},[_vm._v("Actions")])]):_vm._e()],2)]),_c('tbody',_vm._l((_vm.tableData),function(entry){return _c('tr',{key:entry.id,staticClass:"slds-hint-parent"},[_vm._l((_vm.columnsInternal),function(column){return [(column.isHeader)?_c('th',{key:column.field,class:{
            'slds-cell-wrap': column.wrap,
          },attrs:{"role":"gridcell","data-label":entry[column.field]}},[(column.field === 'id' && _vm.type)?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry.name || entry[column.field]}},[_c('router-link',{attrs:{"to":{
                  name: _vm.type,
                  params: _vm.linkParams(column, entry, true),
                  query: _vm.linkQueryString(column, entry),
                }}},[_vm._v(_vm._s(entry[column.field]))])],1)]:(column.type && column.field && entry[column.field] && column.params && column.valueField)?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.field][column.valueField]}},[_c('router-link',{attrs:{"to":{
                  name: column.type,
                  params: _vm.linkParams(column, entry),
                  query: _vm.linkQueryString(column, entry),
                }}},[_vm._v(_vm._s(entry[column.field][column.valueField]))])],1)]:[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.field]}},[_vm._v(" "+_vm._s(_vm.showRelatedObject(entry, column))+" ")])]],2):_c('td',{key:column.field,class:{
            'slds-cell-wrap': column.wrap,
          },attrs:{"role":"gridcell","data-label":entry[column.field]}},[((column.field === 'id' || column.title === 'id') && _vm.type)?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry.name || entry[column.field]}},[_c('router-link',{attrs:{"to":{
                  name: _vm.type,
                  params: _vm.linkParams(column, entry, true),
                  query: _vm.linkQueryString(column, entry),
                }}},[_vm._v(_vm._s(entry[column.field]))])],1)]:(
              column.type &&
              column.parentField &&
              column.childField &&
              entry[column.parentField] &&
              entry[column.parentField][column.childField] &&
              column.params
            )?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.parentField][column.childField]}},[_c('router-link',{attrs:{"to":{
                  name: column.type,
                  params: _vm.linkParams(column, entry),
                  query: _vm.linkQueryString(column, entry),
                }}},[_vm._v(_vm._s(entry[column.parentField][column.childField]))])],1)]:(
              column.type &&
              column.parentField &&
              column.childField &&
              (!entry[column.parentField] || !entry[column.parentField][column.childField]) &&
              column.fallbackField &&
              column.params
            )?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.fallbackField]}},[_vm._v(" "+_vm._s(entry[column.fallbackField])+" ")])]:(column.type && column.params && column.field)?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":column.valueField}},[_c('router-link',{attrs:{"to":{
                  name: column.type,
                  params: _vm.linkParams(column, entry),
                  query: _vm.linkQueryString(column, entry),
                }}},[_vm._v(_vm._s(entry[column.field]))])],1)]:(column.field)?[(typeof entry[column.field] === 'boolean')?_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":_vm._f("humanize")(column.field)}},[_c('div',{staticClass:"slds-checkbox"},[_c('input',{attrs:{"type":"checkbox","name":"options","tabindex":"0","aria-labelledby":"check-button-label-01 column-group-header"},domProps:{"checked":entry[column.field]}}),_vm._m(1,true)])]):_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.field]}},[_vm._v(" "+_vm._s(_vm.showRelatedObject(entry, column))+" ")])]:(column.parentField && column.childField && column.parentField && entry[column.parentField])?[_c('div',{staticClass:"slds-truncate",class:{
                'slds-truncate': column.truncate,
                'slds-line-clamp': column.wrap,
              },attrs:{"title":entry[column.parentField][column.childField]}},[_vm._v(" "+_vm._s(entry[column.parentField][column.childField])+" ")])]:_vm._e()],2)]}),(_vm.showActions)?_c('td',{attrs:{"role":"gridcell"}},[(entry.allowAction || entry.allowAction === null || typeof entry.allowAction === 'undefined')?_c('DataTableActionMenu',{attrs:{"record":entry,"actions":entry.actions},on:{"action":_vm.handleAction}}):_vm._e()],1):_vm._e()],2)}),0),_c('tfoot',[_vm._t("footer")],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slds-resizable"},[_c('input',{staticClass:"slds-resizable__input slds-assistive-text",attrs:{"type":"range","aria-label":"Name column width","max":"1000","min":"20","tabindex":"0"}}),_c('span',{staticClass:"slds-resizable__handle"},[_c('span',{staticClass:"slds-resizable__divider"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"slds-checkbox__label"},[_c('span',{staticClass:"slds-checkbox_faux"}),_c('span',{staticClass:"slds-form-element__label slds-assistive-text"},[_vm._v("Select item 1")])])}]

export { render, staticRenderFns }