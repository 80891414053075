<template>
  <Modal :title="title" @close="handleClose">
    <div slot="sub-header">
      <slot name="sub-header"> </slot>
    </div>
    <div slot="body">
      <slot name="body"> </slot>
    </div>
    <div slot="footer">
      <slot name="footer">
        <button v-if="okBtnText" @click="handleOk" class="slds-button slds-button_brand" :disabled="!isValid">
          {{ okBtnText }}
        </button>
      </slot>
      <button type="reset" class="slds-button slds-button_neutral" @click="handleCancel">
        {{ closeBtnText }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal';

export default {
  name: 'Alert',
  props: {
    isValid: { type: Boolean, default: true },
    okBtnText: { type: String },
    closeBtnText: { type: String, default: 'Cancel' },
    title: String,
  },
  mounted() {},
  methods: {
    handleOk() {
      this.$emit('ok', {});
    },
    handleCancel() {
      this.handleClose();
    },
    handleClose(saved) {
      this.$emit('close', { saved });
    },
  },
  components: {
    Modal,
  },
};
</script>
