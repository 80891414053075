<template>
  <div id="gridView">
    <PageHeader class="slds-m-bottom_small">
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-account">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom62" />
          </svg>
        </span>
      </div>
      <div slot="title">Scan {{ id }}</div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <button
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Refresh List"
              @click="refreshScan"
              :disabled="actionInProgress"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use
                  xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#refresh"
                ></use>
              </svg>
              <span class="slds-assistive-text">Refresh List</span>
            </button>
          </div>
          <div class="slds-page-header__control" v-if="scan && scan.status === COMPLETED">
            <router-link
              v-if="scan"
              :to="{ name: 'items', params: { scanId: id } }"
              role="button"
              class="slds-button slds-button_neutral"
              >View All Scan Items</router-link
            >
          </div>
          <div class="slds-page-header__control" v-if="scan && scan.status === COMPLETED">
            <button class="slds-button slds-button_neutral" @click="download">Export to CSV</button>
          </div>
          <div class="slds-page-header__control" v-if="scan">
            <button class="slds-button slds-button_destructive" @click="handleDelete" :disabled="actionInProgress">
              Delete
            </button>
          </div>
        </div>
      </div>
      <div slot="details" v-if="scan">
        <ul class="slds-page-header__detail-row">
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Status</div>
            <div class="slds-truncate" :title="scan.status">
              {{
                scan.errorLogs.length !== 0 && scan.status === COMPLETED
                  ? scan.status + ' (with errors)'
                  : scan.status | humanize
              }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Created By</div>
            <div class="slds-truncate" :title="scan.createdBy.name">
              {{ scan.createdBy.name }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Start Date</div>
            <div class="slds-truncate" :title="scan.startDate | readableDate">
              {{ scan.startDate | moment('calendar') }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">End Date</div>
            <div class="slds-truncate" :title="scan.endDate | readableDate">
              {{ scan.endDate | moment('calendar') }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Scanner Version</div>
            <div class="slds-truncate" :title="scan.version">
              {{ scan.version }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Metadata</div>
            <div class="slds-truncate" :title="scan.metadata.name">
              <router-link
                :to="{
                  name: 'snapshot',
                  params: { id: scan.metadata.id },
                }"
                >{{ scan.metadata.name }}</router-link
              >
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Connection</div>
            <div class="slds-truncate" :title="scan.metadata.name">
              <router-link
                :to="{
                  name: 'connectionSnapshots',
                  params: { id: scan.metadata.connection.id },
                }"
                >{{ scan.metadata.connection.name }}</router-link
              >
            </div>
          </li>
        </ul>
      </div>
    </PageHeader>
    <div v-if="scan && scan.error === null">
      <template v-if="scan && scan.errorLogs">
        <StaticInformationBox type="error" v-for="error in scan.errorLogs" :key="error.id">
          <span slot="title">Error - {{ error.message }}</span>
          <div slot="body">
            <p>StackTrace: {{ error.stackTrace }}</p>
          </div>
        </StaticInformationBox>
      </template>
    </div>
    <StaticInformationBox type="error" v-if="scan && scan.error !== null && scan.error.length > 0">
      <span slot="title">Scan Error</span>
      <div slot="body">
        <p>
          {{ scan.error }}
        </p>
      </div>
    </StaticInformationBox>
    <article class="slds-card" v-else>
      <div class="slds-card__body slds-card__body_inner">
        <div class="slds-tabs_default">
          <ul class="slds-tabs_default__nav" role="tablist">
            <template v-if="scan && scan.status === COMPLETED">
              <li
                class="slds-tabs_default__item"
                v-bind:class="{
                  'slds-is-active': selectedTab === 'SYSTEM_OVERVIEW',
                }"
                title="System Overview"
                role="presentation"
              >
                <a
                  class="slds-tabs_default__link"
                  href="#"
                  role="tab"
                  tabindex="0"
                  @click="changeTab('SYSTEM_OVERVIEW')"
                  aria-selected="true"
                  aria-controls="tab-default-1"
                  id="tab-default-1__item"
                  >System Overview</a
                >
              </li>
              <li
                class="slds-tabs_default__item"
                title="Other"
                v-bind:class="{
                  'slds-is-active': selectedTab === 'MISCELLANEOUS',
                }"
                role="presentation"
              >
                <a
                  class="slds-tabs_default__link"
                  href="#"
                  @click="changeTab('MISCELLANEOUS')"
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-controls="tab-default-3"
                  >Misc.</a
                >
              </li>
              <li
                class="slds-tabs_default__item"
                title="Security"
                v-bind:class="{
                  'slds-is-active': selectedTab === 'SECURITY',
                }"
                role="presentation"
              >
                <a
                  class="slds-tabs_default__link"
                  href="#"
                  @click="changeTab('SECURITY')"
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-controls="tab-default-3"
                  id="tab-default-3__item"
                  >Security</a
                >
              </li>
              <li
                class="slds-tabs_default__item"
                v-bind:class="{
                  'slds-is-active': selectedTab === 'OBJECTS',
                }"
                title="Objects"
              >
                <a
                  class="slds-tabs_default__link"
                  href="#"
                  @click="changeTab('OBJECTS')"
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-controls="tab-default-2"
                  id="tab-default-2__item"
                  >Objects</a
                >
              </li>
              <li
                class="slds-tabs_default__item"
                v-bind:class="{
                  'slds-is-active': selectedTab === 'REPORTS',
                }"
                title="Reports"
                role="presentation"
              >
                <a
                  class="slds-tabs_default__link"
                  href="#"
                  @click="changeTab('REPORTS')"
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-controls="tab-default-2"
                  id="tab-default-2__item"
                  >Reports</a
                >
              </li>
              <li
                class="slds-tabs_default__item"
                v-bind:class="{
                  'slds-is-active': selectedTab === 'CODE',
                }"
                title="code"
                role="presentation"
              >
                <a
                  class="slds-tabs_default__link"
                  href="#"
                  @click="changeTab('CODE')"
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-controls="tab-default-2"
                  id="tab-default-2__item"
                  >Code</a
                >
              </li>
            </template>
            <li
              class="slds-tabs_default__item"
              title="Other"
              v-bind:class="{
                'slds-is-active': selectedTab === 'LOGS',
              }"
              role="presentation"
            >
              <a
                class="slds-tabs_default__link"
                href="#"
                @click="changeTab('LOGS')"
                role="tab"
                tabindex="-1"
                aria-selected="false"
                aria-controls="tab-default-3"
                >Logs</a
              >
            </li>
          </ul>
          <div
            id="tab-default-1"
            v-if="selectedTab === 'SYSTEM_OVERVIEW'"
            class="slds-tabs_default__content slds-show"
            role="tabpanel"
            aria-labelledby="tab-default-1__item"
          >
            <SystemOverview
              v-if="scan && scan.systemOverviews && scan.systemOverviews.length > 0"
              :systemOverviewRecords="scan.systemOverviews"
            ></SystemOverview>
          </div>
          <div v-if="selectedTab === 'MISCELLANEOUS'" class="slds-tabs_default__content" role="tabpanel">
            <DashboardMiscellaneous :scan="scan"></DashboardMiscellaneous>
          </div>
          <div v-if="selectedTab === 'SECURITY'" class="slds-tabs_default__content" role="tabpanel">
            <DashboardSecurity :scan="scan"></DashboardSecurity>
          </div>
          <div v-if="selectedTab === 'OBJECTS'" class="slds-tabs_default__content slds-show" role="tabpanel">
            <DashboardObjects :scan="scan"></DashboardObjects>
          </div>
          <div v-if="selectedTab === 'REPORTS'" class="slds-tabs_default__content" role="tabpanel">
            <DashboardReports :scan="scan"></DashboardReports>
          </div>
          <div v-if="selectedTab === 'CODE'" class="slds-tabs_default__content" role="tabpanel">
            <DashboardCode :scan="scan"></DashboardCode>
          </div>
          <div v-if="selectedTab === 'LOGS'" class="slds-tabs_default__content" role="tabpanel">
            <DashboardLogs :scan="scan"></DashboardLogs>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import downloader from '../utils/downloader';
import { mapActions, mapState } from 'vuex';
import PageHeader from '../components/PageHeader';
import SystemOverview from '../components/SystemOverview';
import DashboardMiscellaneous from '../components/DashboardMiscellaneous';
import DashboardSecurity from '../components/DashboardSecurity';
import DashboardObjects from '../components/DashboardObjects';
import DashboardReports from '../components/DashboardReports';
import DashboardCode from '../components/DashboardCode';
import DashboardLogs from '../components/DashboardLogs';
import notification from '../utils/notification';
import StaticInformationBox from '../components/StaticInformationBox';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      selectedTab: 'LOGS',
      COMPLETED: 'Completed',
      IN_PROGRESS: 'In Progress',
      user: this.$auth.user,
    };
  },
  async mounted() {
    await this.getScan({ id: this.id, type: this.selectedTab });
    this.selectedTab = this.scan.status === this.COMPLETED ? 'SYSTEM_OVERVIEW' : 'LOGS';
  },
  methods: {
    ...mapActions({
      getScan: 'scans/get',
      deleteScan: 'scans/delete',
    }),
    async changeTab(tabName) {
      this.selectedTab = tabName;
      await this.getScan({ id: this.id, type: tabName });
    },
    async refreshScan() {
      await this.getScan({ id: this.id, type: this.selectedTab });
      this.selectedTab = this.scan.status === this.COMPLETED ? 'SYSTEM_OVERVIEW' : 'LOGS';
    },
    async download() {
      await downloader.download(this.id);
    },
    async handleDelete() {
      await this.deleteScan(this.id);
      notification.success(this, `Delete`, 'Scan is deleted successfully!');
      await this.$router.push({
        name: 'snapshot',
        params: { id: this.snapshot.id },
      });
    },
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      scan: state => state.scans.current,
      snapshot: state => state.snapshots.current,
    }),
  },
  components: {
    PageHeader,
    SystemOverview,
    DashboardObjects,
    DashboardSecurity,
    DashboardMiscellaneous,
    DashboardLogs,
    DashboardReports,
    DashboardCode,
    StaticInformationBox,
  },
};
</script>
<style scoped></style>
