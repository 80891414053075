<template>
  <div class="slds-grid slds-gutters slds-wrap">
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-metrics" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#metrics" />
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Metadata Counts</span>
        </div>
        <table
          slot="body"
          class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
          role="grid"
        >
          <thead>
            <tr class="slds-line-height_reset">
              <th class="" scope="col">
                <div class="slds-truncate" title="Name">Category</div>
              </th>
              <th class="" scope="col">
                <div class="slds-truncate slds-text-align_right" title="Company">Count</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in scan.counts" :key="entry.type">
              <td>
                <router-link
                  :to="{
                    name: 'items',
                    query: { type: entry.type },
                  }"
                  >{{ entry.type | humanize }}</router-link
                >
              </td>
              <td role="gridcell" class="slds-text-align_right">
                {{ entry.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.releaseUpdateCounts && scan.releaseUpdateCounts.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-custom82" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom82" />
            </svg>
            <span class="slds-assistive-text">Release Updates</span>
          </span>
        </div>
        <div slot="title">
          <span>Release Updates</span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'releaseUpdates' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <table
          slot="body"
          class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
          role="grid"
        >
          <thead>
            <tr class="slds-line-height_reset">
              <th class="" scope="col">
                <div class="slds-truncate" title="Name">Category</div>
              </th>
              <th class="" scope="col">
                <div class="slds-truncate slds-text-align_right" title="Company">Count</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="slds-hint-parent" v-for="entry in scan.releaseUpdateCounts" :key="entry.type">
              <th scope="row">
                <div class="slds-truncate" :title="entry.type">
                  <router-link
                    :to="{
                      name: 'releaseUpdates',
                      query: { type: entry.type },
                    }"
                    >{{ entry.type | humanize }}</router-link
                  >
                </div>
              </th>
              <td role="gridcell" class="slds-text-align_right">
                {{ entry.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailCard>
      <DetailCard class="slds-m-bottom_small" v-if="scan.orgLimitStatuses && scan.orgLimitStatuses.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-employee-organization" title="Org Limits">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#employee_organization"
              />
            </svg>
            <span class="slds-assistive-text">Org Limit Status</span>
          </span>
        </div>
        <div slot="title">
          <span>Org Limits</span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'orgLimitStatuses' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <table
          slot="body"
          class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
          role="grid"
        >
          <thead>
            <tr class="slds-line-height_reset">
              <th class="" scope="col">
                <div class="slds-truncate" title="Name">Name</div>
              </th>
              <th class="" scope="col">
                <div class="slds-truncate slds-text-align_right" title="Company">Used</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="slds-hint-parent" v-for="entry in scan.orgLimitStatuses" :key="entry.type">
              <th scope="row">
                <div class="slds-truncate" :title="entry.type">
                  <router-link
                    :to="{
                      name: 'orgLimitStatuses',
                      query: { type: entry.type },
                    }"
                    >{{ entry.type | humanize }}</router-link
                  >
                </div>
              </th>
              <td role="gridcell" class="slds-text-align_right">
                {{ entry.used }}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.licenseUsages && scan.licenseUsages.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-queue" title="License Usages">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#queue" />
            </svg>
            <span class="slds-assistive-text">License Usages</span>
          </span>
        </div>
        <div slot="title">
          <span>License Usages</span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'licenseUsages' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <table
          slot="body"
          class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
          role="grid"
        >
          <thead>
            <tr class="slds-line-height_reset">
              <th class="" scope="col">
                <div class="slds-truncate" title="Name">Name</div>
              </th>
              <th class="" scope="col">
                <div class="slds-truncate slds-text-align_right" title="Company">Used Licenses</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="slds-hint-parent" v-for="entry in scan.licenseUsages" :key="entry.type">
              <th scope="row">
                <div class="slds-truncate" :title="entry.type">
                  <router-link
                    :to="{
                      name: 'licenseUsages',
                      query: { type: entry.type },
                    }"
                    >{{ entry.type | humanize }}</router-link
                  >
                </div>
              </th>
              <td role="gridcell" class="slds-text-align_right">
                {{ entry.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.installedPackages && scan.installedPackages.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-queue" title="License Usages">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#case_change_status"
              />
            </svg>
            <span class="slds-assistive-text">Installed Packages</span>
          </span>
        </div>
        <div slot="title">
          <span>Installed Packages</span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'installedPackages' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <table
          slot="body"
          class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
          role="grid"
        >
          <thead>
            <tr class="slds-line-height_reset">
              <th class="" scope="col">
                <div class="slds-truncate" title="Name">Name</div>
              </th>
              <th class="" scope="col">
                <div class="slds-truncate slds-text-align_right" title="Company">Namespace Prefix</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="slds-hint-parent" v-for="entry in scan.installedPackages" :key="entry.name">
              <th scope="row">
                <div class="slds-truncate" :title="entry.name">
                  {{ entry.name | humanize }}
                </div>
              </th>
              <td role="gridcell" class="slds-text-align_right">
                {{ entry.namespacePrefix }}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailCard>
    </div>
  </div>
</template>
<script>
import DetailCard from './DetailCard';
export default {
  props: {
    scan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  components: {
    DetailCard,
  },
};
</script>
