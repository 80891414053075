<template>
  <div v-if="isVisible" class="container">
    <div class="slds-spinner_container spinner_container">
      <div role="status" class="slds-spinner slds-spinner_medium slds-spinner_inverse">
        <span class="slds-assistive-text">Loading</span>
        <div class="slds-spinner__dot-a"></div>
        <div class="slds-spinner__dot-b"></div>
        <p class="spinner_text">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    isVisible: { type: Boolean, required: true },
    text: { type: String, required: false, default: 'Loading...' },
  },
};
</script>
<style scoped>
.spinner_container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
}
.spinner_text {
  transform: translate(-32%, -50%) rotate(-90deg);
  width: 320px;
  text-align: center;
  color: #ffffff;
}
.container {
  height: 100%;
  background-color: #16325c;
  position: relative;
}
</style>
