<template>
  <div class="toaster slds-notify_container slds-m-top_xx-large" v-if="error">
    <div class="slds-notify slds-notify_toast slds-theme_error toaster_box" role="status">
      <span class="slds-assistive-text">error</span>
      <span
        class="slds-icon_container slds-icon-utility-error slds-m-right_small slds-no-flex slds-align-top"
        title="Description of icon when needed"
      >
        <svg class="slds-icon slds-icon_small" aria-hidden="true">
          <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
        </svg>
      </span>
      <div class="slds-notify__content">
        <h2 class="slds-text-heading_small">{{ error.message }}</h2>
        <p v-if="error.response">{{ error.response.data.message }}</p>
      </div>
      <div class="slds-notify__close">
        <button id="close" class="slds-button slds-button_icon slds-button_icon-inverse" title="Close" @click="close">
          <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Close</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  watch: {
    $route() {
      this.clearError();
    },
  },
  computed: {
    ...mapState({
      error: state => state.app.error,
    }),
  },
  methods: {
    ...mapActions({
      clearError: 'app/clearError',
    }),
    close() {
      this.clearError();
    },
  },
};
</script>

<style scoped>
.toaster {
  width: 50%;
  left: 25%;
}
.toaster_box {
  width: 100%;
}
</style>
