<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    items: Array,
    columns: Array,
    column: { type: String, default: 'id' },
    desc: { type: Boolean, default: false },
  },
  watch: {
    items() {
      this.sortData();
    },
    column() {
      this.sortData();
    },
    desc() {
      this.sortData();
    },
  },
  methods: {
    ...mapMutations({
      setData: 'dataSort/setData',
    }),
    sortData() {
      let column = null;
      if (this.column) {
        column = this.columns.find(({ field }) => field === this.column);
      }
      const desc = this.desc;
      let sortedItems = this.items;

      if (column) {
        sortedItems = sortedItems.slice().sort(function (a, b) {
          if (typeof a[column.field] === 'number' || typeof a[column.field] === 'boolean') {
            a = a[column.field];
            b = b[column.field];
          } else if (a[column.field] === null) {
            return -1;
          } else if (b[column.field] === null) {
            return 1;
          } else {
            a = a[column.field] ? a[column.field].toLowerCase() : a[column.valueField].toLowerCase();
            b = b[column.field] ? b[column.field].toLowerCase() : b[column.valueField].toLowerCase();
          }
          return (a === b ? 0 : a > b ? 1 : -1) * (desc ? -1 : 1);
        });
      }
      this.setData(sortedItems);
    },
  },
  render() {
    return null;
  },
};
</script>
