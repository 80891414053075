<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-opportunity" title="Snapshots">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom38" />
          </svg>
          <span class="slds-assistive-text">Snapshots</span>
        </span>
      </div>
      <div slot="title">
        <span>Snapshots ({{ snapshots.length }})</span>
      </div>
      <div slot="actions">
        <div class="slds-no-flex">
          <div class="slds-no-flex">
            <button class="slds-button slds-button_neutral" @click="newSnapshot()">New Snapshot</button>
            <button
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Refresh List"
              @click="refreshData()"
              :disabled="actionInProgress"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
              </svg>
              <span class="slds-assistive-text">Refresh List</span>
            </button>
            <JsonCSV
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Download CSV"
              :data="snapshots"
              :name="'snapshots.csv'"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use xlink:href="~@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg#download" />
              </svg>
              <span class="slds-assistive-text">Download CSV</span>
            </JsonCSV>
          </div>
        </div>
      </div>
      <div slot="body">
        <DataTable
          type="snapshot"
          :displayRows="snapshotRowData"
          :columns="[
            {
              title: 'Id',
              field: 'id',
            },
            {
              title: 'Name',
              field: 'name',
              width: 200,
              params: {
                id: 'id',
              },
              type: 'snapshot',
            },
            {
              title: 'Status',
              field: 'status',
              width: 120,
            },
            {
              title: 'Connection Org',
              params: {
                id: 'id',
              },
              parentField: 'connection',
              type: 'connectionSnapshots',
              childField: 'name',
            },
            {
              title: 'Created By',
              field: 'createdBy',
              valueField: 'name',
              width: 150,
            },
            {
              title: 'Created At',
              field: 'createdAt',
              width: 200,
            },
            {
              title: 'Completed At',
              field: 'completedAt',
              width: 200,
            },
          ]"
          :showActions="showActions()"
          v-on:rename="handleRename"
          v-on:delete="handleDelete"
        />
      </div>
    </DetailCard>

    <SnapshotEditor
      v-if="snapshotEditorVisible"
      :show="snapshotEditorVisible"
      :snapshot="snapshotToEdit"
      @close="handleSnapshotEditorClose"
    />
    <NewSnapshot v-if="newSnapshotVisible" @close="handleNewSnapshotClose" @upload="handleNewSnapshotClose" />
    <ListSFConnections
      :show="connectionListVisible"
      @close="handleSfConnectionListClose"
      :sfConnections="sfConnections"
    />
    <NewSFConnection :show="newSFConnectionVisible" @close="newSFConnectionVisible = false" />
  </div>
</template>

<script>
import NewSnapshot from '../components/NewSnapshot';
import ListSFConnections from '../components/ListSFConnections';
import NewSFConnection from '../components/NewSFConnection';
import DataTable from '../components/DataTable';
import DetailCard from '../components/DetailCard';
import SnapshotEditor from '../components/SnapshotEditor';
import JsonCSV from 'vue-json-csv';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      snapshotToEdit: null,
      snapshotEditorVisible: false,
      newSnapshotVisible: false,
      connectionListVisible: false,
      newSFConnectionVisible: false,
      showMetadataDeletionConfirmationModal: false,
      selectedSnapshotId: null,
      profileName: this.$auth.user.profile,
    };
  },
  async mounted() {
    await this.refreshData();
    await this.getAllSFConnections();
  },
  methods: {
    ...mapActions({
      refreshData: 'snapshots/getAll',
      delete: 'snapshots/delete',
      getAllSFConnections: 'sfConnections/getAll',
    }),
    async handleDelete(event) {
      await this.delete({ id: event.record.id });
    },
    async handleSnapshotEditorClose(event) {
      this.snapshotEditorVisible = false;
      this.snapshotToEdit = null;
      if (event.saved) {
        await this.refreshData();
      }
    },
    async handleSfConnectionListClose(event) {
      this.connectionListVisible = event.toggleView;
      if (event.isNewConnectionRequest) {
        this.newSFConnectionVisible = true;
      } else if (event.isReloadRequired) {
        await this.refreshData();
      }
    },
    newSnapshot() {
      this.newSnapshotVisible = true;
    },
    handleRename(event) {
      this.snapshotToEdit = event.record;
      this.snapshotEditorVisible = true;
    },
    handleNewSnapshotClose(event) {
      this.newSnapshotVisible = false;
      if (event && event.connectToOrg) {
        this.connectionListVisible = true;
      }
    },
    showActions() {
      return true; // TODO when are we setting this to false?
    },
  },
  computed: {
    ...mapState({
      snapshots(state) {
        return state.snapshots.all.map(snapshot => {
          return {
            ...snapshot,
            allowAction: this.$auth.user.profile === 'sysadmin' || snapshot.owner.username === this.$auth.user.username,
            actions: this.defaultActions,
          };
        });
      },
      sfConnections: state => state.sfConnections.all,
      actionInProgress: state => state.app.actionInProgress,
      defaultActions() {
        let result = [{ label: 'Rename', name: 'rename' }];
        if (this.$auth.user.profile === 'sysadmin') {
          result.push({ label: 'Delete', name: 'delete' });
        }
        return result;
      },
    }),
    snapshotRowData: {
      get() {
        return this.snapshots;
      },
    },
  },
  components: {
    JsonCSV,
    DataTable,
    SnapshotEditor,
    NewSnapshot,
    ListSFConnections,
    NewSFConnection,
    DetailCard,
  },
};
</script>
