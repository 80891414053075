const state = () => ({
  error: null,
  actionInProgress: false,
  packageVersion: process.env.VUE_APP_PACKAGE_VERSION || '0',
});

const getters = {};

const actions = {
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  setError(state, error) {
    state.error = error;
  },
  clearError(state) {
    state.error = null;
  },
  setActionInProgress(state) {
    state.actionInProgress = true;
  },
  clearActionInProgress(state) {
    state.actionInProgress = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
