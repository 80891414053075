<template>
  <div>
    <section
      role="dialog"
      tabindex="-1"
      v-bind:class="classObject"
      aria-labelledby="modal-heading-01"
      aria-modal="true"
      aria-describedby="modal-content-id-1"
    >
      <div class="slds-modal__container">
        <div @click.stop>
          <header class="slds-modal__header">
            <button
              class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
              type="reset"
              @click="handleClose"
            >
              <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
                <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
              </svg>
              <span class="slds-assistive-text">Close</span>
            </button>
            <slot name="header">
              <h2 id="modal-heading-01" class="slds-modal__title slds-hyphenate">
                {{ title }}
              </h2>
            </slot>
            <p class="slds-m-top_x-small">
              <slot name="sub-header"> </slot>
            </p>
          </header>
          <div class="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
            <slot name="body"> </slot>
          </div>
          <footer class="slds-modal__footer">
            <slot name="footer">
              <button class="slds-button slds-button_brand" @click="handleClose">Close</button>
            </slot>
          </footer>
        </div>
      </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open" v-bind:class="{ hideBackdrop: this.hideBackdrop === true }"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    size: String,
    hideBackdrop: Boolean,
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
  computed: {
    classObject: function () {
      return {
        'slds-modal slds-fade-in-open': true,
        'slds-modal_small': this.size === 'small',
        'slds-modal_medium': this.size === 'medium',
        'slds-modal_large': this.size === 'large',
      };
    },
  },
};
</script>
<style>
.hideBackdrop {
  opacity: 0;
}
</style>
