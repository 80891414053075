<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-branch-merge" title="Items">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom34" />
          </svg>
          <span class="slds-assistive-text">PMD</span>
        </span>
      </div>
      <div slot="title">
        <span>PMD ({{ pmds.length }})</span>
      </div>
      <div slot="actions" v-if="isScanAvailable">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <DataSearch :items="filteredItems" :columns="columns" />
          </div>
          <div class="slds-page-header__control">
            <DataFilter :items="pmds" filter-field="ruleset" title="Filter by Ruleset" />
          </div>
          <div class="slds-page-header__control">
            <JsonCSV
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Download CSV"
              :data="pmds"
              :name="'scan-' + id + '-pmds.csv'"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use
                  xlink:href="~@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg#download"
                ></use>
              </svg>
              <span class="slds-assistive-text">Download CSV</span>
            </JsonCSV>
          </div>
        </div>
      </div>
      <div slot="body" v-if="isScanAvailable">
        <DataSort :items="searchedItems" :column="sortColumn" :desc="sortDesc" :columns="columns" />
        <DataTable
          type="item"
          :displayRows="currentPage"
          :columns="columns"
          sortable
          :isAlreadySorted="true"
          :sort-column="sortColumn"
          :sort-desc="sortDesc"
          @sort="handleSort"
        />

        <DataPaginate :per-page="100" :items="sortedItems" />
      </div>
    </DetailCard>
  </div>
</template>

<script>
import DetailCard from '../components/DetailCard';
// import PageHeader from "../components/PageHeader";
import DataFilter from '../components/DataFilter';
import DataSearch from '../components/DataSearch';
import DataTable from '../components/DataTable.vue';
import DataSort from '../components/DataSort.vue';
import DataPaginate from '../components/DataPaginate.vue';
import JsonCSV from 'vue-json-csv';
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      columns: [
        {
          title: 'Id',
          field: 'id',
          params: {
            id: 'itemId',
          },
        },
        {
          title: 'Class Name',
          params: {
            id: 'id',
          },
          type: 'item',
          parentField: 'item',
          childField: 'name',
        },
        { title: 'priority', field: 'priority', width: 100 },
        { title: 'line', field: 'line', width: 100 },
        {
          title: 'ruleset',
          field: 'ruleset',
          width: 150,
        },
        { title: 'rule', field: 'rule', wrap: true },
        { title: 'description', field: 'description', wrap: true },
      ],
      sortColumn: 'id',
      sortDesc: false,
    };
  },
  async mounted() {
    await this.getScan({ id: this.id });
    await this.getAll(this.id);
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      scan: state => state.scans.current,
      pmds: state => state.pmds.all,
      filteredItems: state => state.dataFilter.data,
      searchedItems: state => state.dataSearch.data,
      sortedItems: state => state.dataSort.data,
      currentPage: state => state.dataPaginate.data,
    }),
    isScanAvailable() {
      return !!this.scan;
    },
  },
  methods: {
    ...mapActions({ getScan: 'scans/get' }),
    ...mapCacheActions('pmds', ['getAll']),
    handleSort(event) {
      if (event.column.field === this.sortColumn) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = event.column.field;
        this.sortDesc = false;
      }
    },
  },
  components: {
    DetailCard,
    // PageHeader,
    DataFilter,
    DataSearch,
    DataSort,
    DataTable,
    DataPaginate,
    JsonCSV,
  },
};
</script>
