<template>
  <Modal :title="'Are you sure you want to disconnect from this Org?'" v-show="show" @close="handleClose">
    <div slot="body">
      <slot name="body"
        >Disconnecting only deletes the access token for an Org. Disconnecting an Org from Traction X-Ray does not
        delete the Connection Record, Snapshots or scans related to the Connection. <br /><br />
        <b>Note:</b> Make sure you know the credentials for this Org for future Snapshots or X-Ray scans. If you need to
        collect new Snapshots or run future scans for this Org, you will need to re-authenticate.</slot
      >
    </div>

    <div slot="footer">
      <button type="button" @click="handleDisconnectConnection" class="slds-button slds-button_destructive">
        Disconnect
      </button>

      <button type="reset" class="slds-button slds-button_text-destructive" @click="handleClose">Cancel</button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from './Modal';
import notification from '../utils/notification';

export default {
  name: 'DisconnectConnectionConfirm',

  props: {
    show: { type: Boolean, default: false },
    selectedSnapshotName: { type: String },
    selectedSnapshotId: { type: String },
  },

  mounted() {},

  methods: {
    ...mapActions({
      delete: 'snapshots/delete',
      edit: 'sfConnections/edit',
    }),

    async handleDisconnectConnection() {
      let connection = {};
      connection.name = this.selectedSnapshotName;
      let response = await this.edit({
        id: this.selectedSnapshotId,
        data: connection,
        deleteAuth: true,
      });
      if (!response.error) {
        notification.success(this, `Connection`, 'Connection disconnected successfully!');
      } else {
        notification.error(this, `error`, response.error);
      }

      this.handleClose('deleted');
    },

    handleClose(state = 'closed') {
      this.$emit('close-model', { state });
    },
  },

  components: {
    Modal,
  },
};
</script>
