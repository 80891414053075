<template>
  <div>
    <PageHeader class="slds-m-bottom_small">
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-opportunity">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom38" />
          </svg>
        </span>
      </div>
      <div slot="title" v-if="snapshot">{{ snapshot.name }}</div>
      <div slot="subtitle">Id: {{ id }}</div>
      <div slot="actions" v-if="isSnapshotAvailable">
        <div class="slds-button-group" role="group">
          <button class="slds-button slds-button_neutral" @click="showSnapshotEditor">Rename</button>
          <button
            class="slds-button slds-button_destructive"
            @click="handleDeleteSnapshot"
            :disabled="actionInProgress"
          >
            Delete
          </button>
        </div>
      </div>
      <div slot="details" v-if="snapshot">
        <ul class="slds-page-header__detail-row">
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Status</div>
            <div class="slds-truncate" :title="snapshot.status">
              {{ snapshot.status }}
            </div>
          </li>
          <li class="slds-page-header__detail-block" v-if="snapshot.connection">
            <div class="slds-text-title slds-truncate">Connection Org</div>
            <div class="slds-truncate" :title="snapshot.connection.name">
              <router-link
                :to="{
                  name: 'connectionSnapshots',
                  params: { id: snapshot.connection.id },
                }"
                >{{ snapshot.connection.name }}</router-link
              >
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Created By</div>
            <div class="slds-truncate" :title="snapshot.createdBy.name">
              {{ snapshot.createdBy.name }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Created Date</div>
            <div class="slds-truncate" :title="snapshot.createdAt | readableDate">
              {{ snapshot.createdAt | moment('from') }}
            </div>
          </li>
        </ul>
      </div>
    </PageHeader>
    <template v-if="snapshot && snapshot.errorLogs">
      <StaticInformationBox type="error" v-for="error in snapshot.errorLogs" :key="error.id">
        <span slot="title">Error - {{ error.message }}</span>
        <div slot="body">
          <p>StackTrace: {{ error.stackTrace }}</p>
        </div>
      </StaticInformationBox>
    </template>
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-account" title="scan">
          <svg class="slds-icon slds-icon_small" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom62" />
          </svg>
          <span class="slds-assistive-text">scan</span>
        </span>
      </div>
      <div slot="title">
        <router-link
          :to="{
            name: 'scans',
            params: { snapshotId: id },
          }"
          class="slds-card__header-link slds-truncate"
          title="Scans"
        >
          <span>Scans</span>
        </router-link>
      </div>
      <div slot="actions">
        <div class="slds-no-flex">
          <div class="slds-no-flex">
            <button
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Refresh List"
              @click="handleRefreshScans"
              :disabled="actionInProgress"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
              </svg>
              <span class="slds-assistive-text">Refresh List</span>
            </button>

            <JsonCSV
              v-if="snapshot && !snapshot.error"
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Download CSV"
              :data="scans"
              :name="'snapshot-' + id + '-scans.csv'"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use xlink:href="~@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg#download" />
              </svg>
              <span class="slds-assistive-text">Download CSV</span>
            </JsonCSV>
          </div>
        </div>
      </div>
      <div slot="body">
        <div v-if="snapshot && snapshot.error" style="margin: 10px">
          <StaticInformationBox type="error">
            <span slot="title">Snapshot Error</span>
            <div slot="body">
              <pre class="slds-text-font_monospace slds-text-body_small">{{ snapshot.error }}</pre>
            </div>
          </StaticInformationBox>
        </div>
        <DataTable
          type="scan"
          :display-rows="scans"
          :columns="[
            {
              title: 'Id',
              field: 'id',
            },
            {
              title: 'Status',
              field: 'status',
            },
            {
              title: 'Created By',
              field: 'createdBy',
            },
            {
              title: 'Start Date',
              field: 'startDate',
            },
            {
              title: 'End Date',
              field: 'endDate',
            },
          ]"
          :actions="[{ label: 'Delete', name: 'delete' }]"
          v-on:delete="handleDeleteScan"
        />
      </div>
    </DetailCard>
    <SnapshotLogs :snapshot="snapshot"></SnapshotLogs>
    <SnapshotEditor
      v-if="snapshotEditorVisible"
      :show="snapshotEditorVisible"
      :snapshot="snapshot"
      @close="handleSnapshotEditorClose"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageHeader from '../components/PageHeader';
import DetailCard from '../components/DetailCard';
import DataTable from '../components/DataTable';
import SnapshotEditor from '../components/SnapshotEditor';
import StaticInformationBox from '../components/StaticInformationBox';
import notification from '../utils/notification';
import SnapshotLogs from '../components/SnapshotLogs';
import JsonCSV from 'vue-json-csv';
import constants from '../utils/constants';

export default {
  props: {
    id: { type: String, required: true },
  },
  async mounted() {
    await this.refreshScans(this.id);
    await this.getSnapshot(this.id);
    this.getSnapshotError();
  },
  data() {
    return {
      snapshotEditorVisible: false,
      errorText: null,
      snapshotId: this.id,
      user: this.$auth.user,
    };
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      snapshot: state => state.snapshots.current,
      scans(state) {
        return state.scans.all.map(scan => {
          return {
            ...scan,
            status:
              scan.hasErrors && scan.status === constants.COMPLETED ? scan.status + ' (with errors)' : scan.status,
            allowAction:
              state.snapshots.current &&
              !(
                this.$auth.user.profile === 'readOnlyAdmin' &&
                state.snapshots.current.owner.username !== this.$auth.user.username
              ),
          };
        });
      },
    }),
    isSnapshotAvailable() {
      return !!this.snapshot;
    },
  },
  methods: {
    ...mapActions({
      getSnapshot: 'snapshots/get',
      deleteSnapshot: 'snapshots/delete',
      deleteScan: 'scans/delete',
      refreshScans: 'scans/getAll',
    }),
    showSnapshotEditor() {
      this.snapshotEditorVisible = true;
    },
    async handleSnapshotEditorClose(event) {
      this.snapshotEditorVisible = false;
      if (event.saved) {
        await this.getSnapshot(this.id);
      }
    },
    async handleDeleteSnapshot() {
      await this.deleteSnapshot({ id: this.id });
      await this.$router.push({ name: 'snapshots' });
    },
    async handleRefreshScans() {
      await this.refreshScans(this.id);
    },
    async handleDeleteScan(event) {
      await this.deleteScan({
        id: event.record.id.toString(),
        snapshotId: this.id,
      });
      notification.success(this, `Delete`, `Scan is deleted successfully!`);
    },
    getSnapshotError() {
      if (this.snapshot && this.snapshot.error !== null) {
        this.errorText = JSON.parse(this.snapshot.error).message;
      }
    },
  },
  components: {
    DataTable,
    JsonCSV,
    DetailCard,
    PageHeader,
    SnapshotLogs,
    SnapshotEditor,
    StaticInformationBox,
  },
};
</script>
