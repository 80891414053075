import api from '../../utils/api';

const state = () => ({
  all: [],
  current: null,
});

const getters = {};

const actions = {
  async getAll({ commit, rootState }, snapshotId) {
    const path = snapshotId ? `/metadatas/${snapshotId}/scans` : '/scans';

    commit('app/setActionInProgress', null, { root: true });
    try {
      const items = await api.get({ path });
      commit('setAll', items);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async get({ commit, rootState }, { id, type }) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const snapshot = await api.get({
        path: `/scans/${id}?type=${type}`,
      });
      commit('setCurrent', snapshot);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async download({ commit, rootState }, id) {
    commit('app/setActionInProgress', null, { root: true });
    let file;
    try {
      file = await api.getFile({
        path: `/scans/${id}/exportCSV`,
      });
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
    return file;
  },

  async snapshotScan({ commit, rootState }, body) {
    commit('app/setActionInProgress', null, { root: true });
    const sfConnection = rootState.sfConnections.current;
    try {
      const { statusCode, message } = await api.post({
        path: `/scans`,
        body: { isBenchmark: false, ...body },
      });

      if (statusCode === 400) {
        this._vm.$notify(
          {
            group: 'notifications',
            type: 'error',
            title: `Connection: ${sfConnection.name}`,
            text: message,
          },
          6000
        );
      } else {
        this._vm.$notify(
          {
            group: 'notifications',
            type: 'info',
            title: `Connection: ${sfConnection.name}`,
            text: `Snapshot started, this may take a few moments`,
          },
          6000
        );
      }
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async delete({ commit, rootState, dispatch }, payload) {
    let id;
    let snapshotId;
    if (payload instanceof Object) {
      ({ id, snapshotId } = payload);
    } else {
      id = payload;
    }

    commit('app/setActionInProgress', null, { root: true });
    try {
      await api.delete({ path: `/scans/${id}` });
      if (snapshotId) {
        dispatch('getAll', snapshotId);
      }
      commit('app/clearActionInProgress', null, { root: true });
    } catch (error) {
      commit('app/setError', error, { root: true });
      commit('app/clearActionInProgress', null, { root: true });
      throw error;
    }
  },
};

const mutations = {
  setAll(state, all) {
    state.all = all;
  },
  setId(state, id) {
    state.id = id;
  },
  setCurrent(state, current) {
    state.current = current;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
