<template>
  <div id="gridView">
    <PageHeader>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-account" title="scan">
          <svg class="slds-icon slds-icon_small" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom62"></use>
          </svg>
          <span class="slds-assistive-text">scan</span>
        </span>
      </div>
      <div slot="title">Scans</div>
      <div slot="subtitle">{{ scans.length }} records</div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <button
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Refresh List"
              @click="refreshScans"
              :disabled="actionInProgress"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use
                  xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#refresh"
                ></use>
              </svg>
              <span class="slds-assistive-text">Refresh List</span>
            </button>
          </div>
          <div class="slds-page-header__control">
            <JsonCSV
              class="slds-button slds-button_icon slds-button_icon-border-filled"
              title="Download CSV"
              :data="scans"
              :name="'snapshot-' + id + '-scans.csv'"
              v-if="id"
            >
              <svg class="slds-button__icon" aria-hidden="true">
                <use
                  xlink:href="~@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg#download"
                ></use>
              </svg>
              <span class="slds-assistive-text">Download CSV</span>
            </JsonCSV>
          </div>
        </div>
      </div>
    </PageHeader>

    <DataTable
      type="scan"
      :displayRows="scans"
      :columns="[
        {
          title: 'Id',
          field: 'id',
        },
        {
          title: 'Metadata',
          params: {
            id: 'id',
          },
          parentField: 'metadata',
          type: 'snapshot',
          childField: 'name',
        },
        {
          title: 'Status',
          field: 'status',
        },

        {
          title: 'Created By',
          field: 'createdBy',
        },
        {
          title: 'Start Date',
          field: 'startDate',
        },
        {
          title: 'End Date',
          field: 'endDate',
        },
      ]"
      :actions="[
        { label: 'Export to CSV', name: 'download' },
        { label: 'Delete', name: 'delete' },
      ]"
      v-on:download="handleDownload"
      v-on:delete="handleDelete"
    />
  </div>
</template>

<script>
import DataTable from '../components/DataTable.vue';
import downloader from '../utils/downloader';
import PageHeader from '../components/PageHeader';
import JsonCSV from 'vue-json-csv';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    id: { type: String, required: false },
  },
  async mounted() {
    if (this.id) {
      await this.getSnapshot(this.id);
      await this.refreshScans(this.id);
    } else {
      await this.refreshScans();
    }
  },
  methods: {
    ...mapActions({
      deleteScan: 'scans/delete',
      refreshScans: 'scans/getAll',
      getSnapshot: 'snapshots/get',
    }),
    async handleDownload(event) {
      await downloader.download(event.id);
    },
    async handleDelete(event) {
      await this.deleteScan({ id: event.id, snapshotId: this.id });
    },
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      scans: state => state.scans.all,
    }),
  },
  components: {
    PageHeader,
    JsonCSV,
    DataTable,
  },
};
</script>
