<template>
  <div id="gridView">
    <PageHeader class="slds-m-bottom_small">
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-case">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom4"></use>
          </svg>
        </span>
      </div>
      <div slot="title" v-if="item">{{ item.name }}</div>
      <div slot="subtitle">Id: {{ id }}</div>
      <div slot="details" v-if="item">
        <ul class="slds-page-header__detail-row">
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Category</div>
            <div class="slds-truncate" :title="item.typeDirectory">
              {{ item.typeDirectory | humanize }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Extension</div>
            <div class="slds-truncate" :title="item.extension">
              {{ item.extension }}
            </div>
          </li>
        </ul>
      </div>
    </PageHeader>

    <div v-if="item">
      <DetailCard v-if="showChildren">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Children</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :displayRows="item.children"
            :columns="[
              {
                title: 'id',
                field: 'id',
              },
              {
                title: 'name',
                field: 'name',
              },
              {
                title: 'isDirectory',
                field: 'isDirectory',
                width: 150,
              },
              {
                title: 'Notes',
                field: 'notes',
                width: 200,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.codeCount">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-custom38" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#apex"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Code Count</span>
        </div>
        <div slot="body">
          <DataTable
            :displayRows="[item.codeCount]"
            :columns="[
              {
                title: 'source',
                field: 'source',
              },
              {
                title: 'comment',
                field: 'comment',
              },
              {
                title: 'todo',
                field: 'todo',
              },
              {
                title: 'block',
                field: 'block',
              },
              {
                title: 'blockEmpty',
                field: 'blockEmpty',
              },
              {
                title: 'empty',
                field: 'empty',
              },
              {
                title: 'mixed',
                field: 'mixed',
              },
              {
                title: 'single',
                field: 'single',
              },
              {
                title: 'total',
                field: 'total',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="showClassTypes">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-asset-action" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#all"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Class Types</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.classTypes"
            :columns="[
              {
                title: 'classFamily',
                field: 'classFamily',
              },
              {
                title: 'classType',
                field: 'classType',
              },
              {
                title: 'dependentId',
                field: 'dependentId',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="showPmds">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-branch-merge" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom34"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>PMD Issues</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.pmds"
            :columns="[
              {
                title: 'problem',
                field: 'problem',
                width: 100,
              },
              {
                title: 'line',
                field: 'line',
                width: 100,
              },
              {
                title: 'ruleset',
                field: 'ruleset',
                width: 200,
              },
              {
                title: 'severity',
                field: 'priority',
                width: 100,
              },
              {
                title: 'rule',
                field: 'rule',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
            ]"
          />
        </div>
      </DetailCard>
      <DetailCard v-if="showESLints">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-branch-merge" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom34"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>ES-Lint Issues</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.esLints"
            :columns="[
              { title: 'Id', field: 'id' },
              {
                title: 'rule',
                field: 'rule',
                width: 100,
                wrap: true,
              },
              {
                title: 'line',
                field: 'line',
                width: 70,
              },
              {
                title: 'severity',
                field: 'severity',
                width: 100,
              },
              {
                title: 'category',
                field: 'category',
                width: 200,
              },
              {
                title: 'rule',
                field: 'rule',
                wrap: true,
                width: 200,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.sobject">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Object</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="[item.sobject]"
            :columns="[
              {
                title: 'label',
                field: 'label',
                width: 200,
              },
              {
                title: 'apiName',
                field: 'apiName',
                width: 200,
              },
              {
                title: 'type',
                field: 'type',
                width: 150,
              },
              {
                title: 'isReportable',
                field: 'isReportable',
                width: 100,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.objectFieldCount">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Field Count</span>
          </span>
        </div>
        <div slot="title">
          <span>Field Count</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.objectFieldCount"
            :columns="[
              {
                title: 'Name',
                field: 'name',
                width: 200,
              },
              {
                title: 'Api Name',
                field: 'apiName',
                width: 200,
              },
              {
                title: 'type',
                field: 'type',
                width: 100,
              },
              {
                title: 'Total',
                field: 'Total',
                width: 100,
              },
              { title: 'Text', field: 'Text' },
              { title: 'Picklist', field: 'Picklist' },
              { title: 'Checkbox', field: 'Checkbox' },
              { title: 'AutoNumber', field: 'AutoNumber' },
              {
                title: 'Number',
                field: 'Number',
              },
              {
                title: 'Currency',
                field: 'Currency',
              },
              {
                title: 'LongTextArea',
                field: 'LongTextArea',
              },
              {
                title: 'Lookup',
                field: 'Lookup',
              },
              {
                title: 'MasterDetail',
                field: 'MasterDetail',
              },
              {
                title: 'Date',
                field: 'Date',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.fields && item.fields.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Fields</span>
          </span>
        </div>
        <div slot="title">
          <span>Fields</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.fields"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'Name',
                field: 'label',
                width: 200,
              },
              {
                title: 'Api Name',
                field: 'apiName',
                width: 200,
              },
              {
                title: 'type',
                field: 'type',
                width: 100,
              },
              {
                title: 'length',
                field: 'length',
                width: 100,
              },
              { title: 'picklistValues', field: 'picklistValues', wrap: true },
              { title: 'isMasterDetail', field: 'isMasterDetail' },
              {
                title: 'isLookup',
                field: 'isLookup',
              },
              {
                title: 'referenceTo',
                field: 'referenceTo',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.objectAutomationCount && item.objectAutomationCount.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Automations</span>
          </span>
        </div>
        <div slot="title">
          <span>Automations</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.objectAutomationCount"
            :columns="[
              {
                title: 'activeTrigger',
                field: 'activeTriggerCount',
              },
              {
                title: 'deactiveTrigger',
                field: 'deactiveTriggerCount',
              },
              {
                title: 'activeWorkflow',
                field: 'activeWorkflowCount',
              },
              {
                title: 'deactiveWorkflow',
                field: 'deactiveWorkflowCount',
              },
              {
                title: 'workflowAction',
                field: 'workflowActionCount',
              },
              {
                title: 'fieldUpdateAction',
                field: 'fieldUpdateActionCount',
              },
              {
                title: 'activeFlow',
                field: 'activeFlowCount',
              },
              {
                title: 'deactiveFlow',
                field: 'deactiveFlowCount',
              },
              {
                title: 'Active Total',
                field: 'activeAutomationTotal',
              },
              {
                title: 'Total',
                field: 'automationTotal',
                isHeader: true,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.recordTypes">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Record Types</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.recordTypes"
            :columns="[
              {
                title: 'label',
                field: 'label',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="showWorkflows || (item.workflows && item.workflows.length > 0)">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Workflows</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.workflows"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'triggerType',
                field: 'triggerType',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="showWorkflows">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Actions</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.actions"
            :columns="[
              {
                title: 'name',
                field: 'name',
              },
              {
                title: 'apiName',
                field: 'apiName',
              },
              {
                title: 'type',
                field: 'type',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.trigger">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Trigger</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="[item.trigger]"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'name',
                field: 'name',
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'beforeInsert',
                field: 'beforeInsert',
              },
              {
                title: 'afterInsert',
                field: 'afterInsert',
              },
              {
                title: 'beforeUpdate',
                field: 'beforeUpdate',
              },
              {
                title: 'afterUpdate',
                field: 'afterUpdate',
              },
              {
                title: 'beforeDelete',
                field: 'beforeDelete',
              },
              {
                title: 'afterDelete',
                field: 'afterDelete',
              },
              {
                title: 'afterUndelete',
                field: 'afterUndelete',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.field">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Field</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="[item.field]"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
              },
              {
                title: 'type',
                field: 'type',
              },
              {
                title: 'picklistValues',
                field: 'picklistValues',
                wrap: true,
              },
              {
                title: 'referenceTo',
                field: 'referenceTo',
              },
              {
                title: 'isLookup',
                field: 'isLookup',
              },
              {
                title: 'isMasterDetail',
                field: 'isMasterDetail',
              },
              {
                title: 'globalPicklistName',
                field: 'globalPicklistName',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.triggers && item.triggers.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Triggers</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.triggers"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'name',
                field: 'name',
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'beforeInsert',
                field: 'beforeInsert',
              },
              {
                title: 'afterInsert',
                field: 'afterInsert',
              },
              {
                title: 'beforeUpdate',
                field: 'beforeUpdate',
              },
              {
                title: 'afterUpdate',
                field: 'afterUpdate',
              },
              {
                title: 'beforeDelete',
                field: 'beforeDelete',
              },
              {
                title: 'afterDelete',
                field: 'afterDelete',
              },
              {
                title: 'afterUndelete',
                field: 'afterUndelete',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.sharingRules && item.sharingRules.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Sharing Rules</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.sharingRules"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
                width: 120,
              },
              {
                title: 'Label',
                field: 'name',
              },
              {
                title: 'Type',
                field: 'type',
              },
              {
                title: 'Access Level',
                field: 'accessLevel',
              },
              {
                title: 'Shared With',
                field: 'sharedWith',
                wrap: true,
              },
              {
                title: 'Criteria',
                field: 'criteria',
                wrap: true,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.flow">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Flow</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="[item.flow]"
            :columns="[
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'status',
                field: 'status',
              },
              {
                title: 'triggerType',
                field: 'triggerType',
              },
              {
                title: 'recordTriggerType',
                field: 'recordTriggerType',
              },
              {
                title: 'processType',
                field: 'processType',
              },
              {
                title: 'builderType',
                field: 'builderType',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.flows && item.flows.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Flows</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.flows"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'status',
                field: 'status',
              },
              {
                title: 'triggerType',
                field: 'triggerType',
              },
              {
                title: 'recordTriggerType',
                field: 'recordTriggerType',
              },
              {
                title: 'processType',
                field: 'processType',
              },
              {
                title: 'builderType',
                field: 'builderType',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.processes && item.processes.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Processes</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.processes"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'status',
                field: 'status',
              },
              {
                title: 'triggerType',
                field: 'triggerType',
              },
              {
                title: 'recordTriggerType',
                field: 'recordTriggerType',
              },
              {
                title: 'processType',
                field: 'processType',
              },
              {
                title: 'builderType',
                field: 'builderType',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.approvalProcesses && item.approvalProcesses.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Approval Processes</span>
          </span>
        </div>
        <div slot="title">
          <span>Approval Processes</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.approvalProcesses"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.reportType">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Report Type</span>
          </span>
        </div>
        <div slot="title">
          <span>Report Type</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="[item.reportType]"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'apiName',
                field: 'apiName',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'autogenerated',
                field: 'autogenerated',
              },
              {
                title: 'category',
                field: 'category',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.reportTypes && item.reportTypes.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Report Types</span>
          </span>
        </div>
        <div slot="title">
          <span>Report Types</span>
        </div>
        <div slot="body">
          <DataTable
            type="item"
            :display-rows="item.reportTypes"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'apiName',
                field: 'apiName',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'autogenerated',
                field: 'autogenerated',
              },
              {
                title: 'category',
                field: 'category',
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="reports && reports.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Reports</span>
          </span>
        </div>
        <div slot="title">
          <span>Reports</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="reports"
            type="item"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'reportSfId',
                field: 'reportSfId',
                wrap: true,
              },
              {
                title: 'folderName',
                field: 'folderName',
                wrap: true,
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'apiName',
                field: 'apiName',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'createdDate',
                field: 'createdDate',
                wrap: true,
              },
              {
                title: 'lastReferencedDate',
                field: 'lastReferencedDate',
                wrap: true,
              },
              {
                title: 'lastRunDate',
                field: 'lastRunDate',
                wrap: true,
              },
              {
                title: 'lastViewedDate',
                field: 'lastViewedDate',
                wrap: true,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.report">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Report</span>
          </span>
        </div>
        <div slot="title">
          <span>Report</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="[item.report]"
            type="item"
            :columns="[
              {
                title: 'id',
                field: 'itemId',
              },
              {
                title: 'reportSfId',
                field: 'reportSfId',
                wrap: true,
              },
              {
                title: 'folderName',
                field: 'folderName',
                wrap: true,
              },
              {
                title: 'label',
                field: 'label',
                wrap: true,
              },
              {
                title: 'apiName',
                field: 'apiName',
                wrap: true,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'createdDate',
                field: 'createdDate',
                wrap: true,
              },
              {
                title: 'lastReferencedDate',
                field: 'lastReferencedDate',
                wrap: true,
              },
              {
                title: 'lastRunDate',
                field: 'lastRunDate',
                wrap: true,
              },
              {
                title: 'lastViewedDate',
                field: 'lastViewedDate',
                wrap: true,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.sfProfile">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Profile</span>
          </span>
        </div>
        <div slot="title">
          <span>Profile</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="[item.sfProfile]"
            :columns="[
              {
                title: 'Name',
                field: 'name',
                wrap: true,
                width: 150,
              },
              {
                title: 'userLicense',
                field: 'userLicense',
                wrap: true,
                width: 150,
              },
              {
                title: 'Is custom Profile',
                field: 'custom',
                wrap: 70,
              },
              {
                title: 'editReadonlyFields',
                field: 'editReadonlyFields',
                width: 70,
              },
              {
                title: 'manageLoginAccessPolicies',
                field: 'manageLoginAccessPolicies',
                width: 70,
              },
              {
                title: 'managePasswordPolicies',
                field: 'managePasswordPolicies',
                width: 70,
              },
              {
                title: 'manageProfilesPermissionsets',
                field: 'manageProfilesPermissionsets',
                width: 70,
              },
              {
                title: 'manageRoles',
                field: 'manageRoles',
                width: 70,
              },
              {
                title: 'manageSharing',
                field: 'manageSharing',
                width: 70,
              },
              {
                title: 'manageUsers',
                field: 'manageUsers',
                width: 70,
              },
              {
                title: 'modifyAllData',
                field: 'modifyAllData',
                width: 70,
              },
              {
                title: 'resetPasswords',
                field: 'resetPasswords',
                width: 70,
              },
              {
                title: 'viewAllData',
                field: 'viewAllData',
                width: 70,
              },
              {
                title: 'exportReport',
                field: 'exportReport',
                width: 70,
              },
              {
                title: 'dataExport',
                field: 'dataExport',
                width: 70,
              },
              {
                title: 'modifyAllPermission',
                field: 'modifyAllPermission',
                width: 70,
              },
              {
                title: 'User Count',
                field: 'count',
                width: 100,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.permissionSet">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Permission Set</span>
          </span>
        </div>
        <div slot="title">
          <span>Permission Set</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="[item.permissionSet]"
            :columns="[
              {
                title: 'Name',
                field: 'name',
                wrap: true,
                width: 150,
              },
              {
                title: 'userLicense',
                field: 'userLicense',
                wrap: true,
                width: 150,
              },
              {
                title: 'description',
                field: 'description',
                width: 150,
                wrap: true,
              },
              {
                title: 'editReadonlyFields',
                field: 'editReadonlyFields',
                width: 70,
              },
              {
                title: 'manageLoginAccessPolicies',
                field: 'manageLoginAccessPolicies',
                width: 70,
              },
              {
                title: 'managePasswordPolicies',
                field: 'managePasswordPolicies',
                width: 70,
              },
              {
                title: 'manageProfilesPermissionsets',
                field: 'manageProfilesPermissionsets',
                width: 70,
              },
              {
                title: 'manageRoles',
                field: 'manageRoles',
                width: 70,
              },
              {
                title: 'manageSharing',
                field: 'manageSharing',
                width: 70,
              },
              {
                title: 'manageUsers',
                field: 'manageUsers',
                width: 70,
              },
              {
                title: 'modifyAllData',
                field: 'modifyAllData',
                width: 70,
              },
              {
                title: 'resetPasswords',
                field: 'resetPasswords',
                width: 70,
              },
              {
                title: 'viewAllData',
                field: 'viewAllData',
                width: 70,
              },
              {
                title: 'exportReport',
                field: 'exportReport',
                width: 70,
              },
              {
                title: 'dataExport',
                field: 'dataExport',
                width: 70,
              },
              {
                title: 'modifyAllPermission',
                field: 'modifyAllPermission',
                width: 70,
              },
              {
                title: 'User Count',
                field: 'count',
                width: 100,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="item.role">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">Role</span>
          </span>
        </div>
        <div slot="title">
          <span>Role</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="[item.role]"
            :columns="[
              {
                title: 'Name',
                field: 'name',
                wrap: true,
                width: 150,
              },
              {
                title: 'apiName',
                field: 'apiName',
                wrap: true,
                width: 150,
              },
              {
                title: 'description',
                field: 'description',
                wrap: true,
              },
              {
                title: 'caseAccessLevel',
                field: 'caseAccessLevel',
                width: 100,
              },
              {
                title: 'mayForecastManagerShare',
                field: 'mayForecastManagerShare',
                width: 100,
              },
              {
                title: 'opportunityAccessLevel',
                field: 'opportunityAccessLevel',
                width: 100,
              },
              {
                title: 'contactAccessLevel',
                field: 'contactAccessLevel',
                width: 100,
              },
              {
                title: 'User Count',
                field: 'count',
                width: 100,
              },
            ]"
          />
        </div>
      </DetailCard>

      <DetailCard v-if="showWorkflows">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-custom-8" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom8"></use>
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Workflow Actions</span>
        </div>
        <div slot="body">
          <DataTable
            :display-rows="item.workflowActions"
            :columns="[
              {
                title: 'workflow',
                field: 'workflow',
              },
              {
                title: 'action',
                field: 'action',
              },
            ]"
          />
        </div>
      </DetailCard>
    </div>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader';
import DetailCard from '../components/DetailCard';
import DataTable from '../components/DataTable.vue';
import { mapActions, mapState } from 'vuex';

const PMD_EXTENSIONS = new Set(['.cls', '.trigger']);
const ESLINT_EXTENSIONS = new Set(['.js']);

export default {
  props: {
    id: { type: String, required: true },
  },
  async mounted() {
    await this.getItem(this.id);
  },
  watch: {
    async $route() {
      await this.getItem(this.id);
    },
  },
  computed: {
    ...mapState({
      scan: state => state.scans.current,
      item: state => state.items.current,
    }),
    errorLineNumbers() {
      return this.item.pmds.length > 0 ? this.item.pmds : this.item.esLints;
    },
    showChildren() {
      return this.item.children && this.item.children.length > 0;
    },
    showClassTypes() {
      return this.item.extension === '.cls';
    },
    showPmds() {
      return PMD_EXTENSIONS.has(this.item.extension);
    },
    showESLints() {
      return ESLINT_EXTENSIONS.has(this.item.extension);
    },
    showWorkflows() {
      return this.item.typeDirectory === 'workflows';
    },
    reports() {
      if (this.item.reportTypes && this.item.reportTypes.length > 0) {
        const reports = [...this.item.reportTypes.map(reportType => reportType.reports || [])];
        return reports.flat();
      } else if (this.item.reportType) {
        return this.item.reportType.reports;
      }
      return [];
    },
    language() {
      const languageType = this.item.extension ? this.item.extension.split('.')[1] : '';
      switch (languageType) {
        case 'cls':
          return 'java';
        case 'trigger':
          return 'java';
        case 'xml':
          return 'xml';
        case 'page':
          return 'html';
        default:
          return languageType;
      }
    },
  },
  data() {
    return {
      user: this.$auth.user,
    };
  },
  methods: {
    ...mapActions({
      getItem: 'items/get',
    }),
    showActionButton() {
      return (
        this.user &&
        this.scan &&
        (this.scan.createdBy.username === this.$auth.user.username || this.$auth.user.profile === 'sysadmin')
      );
    },
  },
  components: {
    PageHeader,
    DetailCard,
    DataTable,
  },
};
</script>
