import store from '../store';

export default {
  async download(id) {
    //const scanId = store.state.scans.id;
    const data = await store.dispatch('scans/download', id);

    if (store.state.app.error) {
      return;
    }

    // TODO: Determine how to make this less janky
    const fileLink = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(new Blob([data]));
    fileLink.setAttribute('download', `scan-${id}.zip`);

    document.body.appendChild(fileLink);

    fileLink.click();
  },
};
