import Vue from 'vue';
import Vuex from 'vuex';
import createCache from 'vuex-cache';
import app from './modules/app';
import snapshots from './modules/snapshots';
import sfConnections from './modules/sfConnections';
import scans from './modules/scans';
import items from './modules/items';
import dataFilter from './modules/dataFilter';
import dataSearch from './modules/dataSearch';
import dataSort from './modules/dataSort';
import dataPaginate from './modules/dataPaginate';
import pmds from './modules/pmds';
import esLints from './modules/esLints';
import releaseUpdates from './modules/releaseUpdates';
import orgLimitStatuses from './modules/orgLimitStatuses';
import roles from './modules/roles';
import sfProfiles from './modules/sfProfiles';
import reportTypes from './modules/reportTypes';
import reports from './modules/reports';
import permissionSets from './modules/permissionSets';
import groups from './modules/groups';
import sharingRules from './modules/sharingRules';
import licenseUsages from './modules/licenseUsages';
import installedPackages from './modules/installedPackages';
import objectFieldCounts from './modules/objectFieldCounts';
import objectApprovalProcessCounts from './modules/objectApprovalProcessCounts';
import objectAutomations from './modules/objectAutomations';
import securityHealthCheckRisks from './modules/securityHealthCheckRisks';
import securityHealthCheck from './modules/securityHealthCheck';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    snapshots,
    sfConnections,
    scans,
    items,
    dataFilter,
    dataSearch,
    dataPaginate,
    dataSort,
    pmds,
    esLints,
    objectFieldCounts,
    objectApprovalProcessCounts,
    objectAutomations,
    releaseUpdates,
    orgLimitStatuses,
    roles,
    sfProfiles,
    reportTypes,
    reports,
    permissionSets,
    groups,
    sharingRules,
    securityHealthCheckRisks,
    securityHealthCheck,
    licenseUsages,
    installedPackages,
  },
  plugins: [createCache({ timeout: 60 * 60 * 1000 })], // set a one hour cache timeout
  strict: debug,
});
