const state = () => ({
  data: [],
});

const getters = {};
const actions = {};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
