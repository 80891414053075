<template>
  <div>
    <button
      class="slds-button slds-button_icon slds-button_icon-border-filled"
      :class="{ 'slds-is-selected': isActive }"
      title="Filter List"
      @click="showModal"
      @close="closeModal"
    >
      <svg class="slds-button__icon" aria-hidden="true">
        <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
      </svg>
      <span class="slds-assistive-text">Filter List</span>
    </button>

    <Modal :title="title" v-show="isModalVisible" @close="closeModal">
      <template v-slot:body>
        <div id="filters" class="slds-form-element">
          <label for="all" class="slds-checkbox_toggle slds-grid">
            <input
              type="checkbox"
              name="all"
              value="all"
              aria-describedby="all"
              id="all"
              :checked="allChecked"
              @change="allChanged"
            />
            <span class="slds-checkbox_faux_container" aria-live="assertive">
              <span class="slds-checkbox_faux"></span>
              <span class="slds-checkbox_on">Enabled</span>
              <span class="slds-checkbox_off">Disabled</span>
            </span>
            <span class="slds-form-element__label slds-m-bottom_none">All</span>
          </label>

          <div v-for="filter in filters" :key="filter.name">
            <label :for="filter.name" class="slds-checkbox_toggle slds-grid">
              <input
                type="checkbox"
                :name="filter.name"
                :value="filter.name"
                :aria-describedby="filter.name"
                :id="filter.name"
                :checked="filter.checked"
                @change="filterChanged(filter.name, filter.checked)"
              />
              <span id="checkbox-toggle-16" class="slds-checkbox_faux_container" aria-live="assertive">
                <span class="slds-checkbox_faux"></span>
                <span class="slds-checkbox_on">Enabled</span>
                <span class="slds-checkbox_off">Disabled</span>
              </span>
              <span class="slds-form-element__label slds-m-bottom_none">{{ filter.label }}</span>
            </label>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal';
import { mapMutations } from 'vuex';

export default {
  props: {
    items: Array,
    filterField: { type: String, required: true },
    title: { type: String, default: 'Filter' },
  },
  data() {
    return {
      isModalVisible: false,
      allChecked: true,
      filters: [],
    };
  },
  mounted() {
    this.buildFilters();
    this.updateFiltersFromTypeParam();
    this.filterData();
  },
  watch: {
    items() {
      this.buildFilters();
      this.updateFiltersFromTypeParam();
      this.filterData();
    },
  },
  computed: {
    isActive() {
      return !!this.filters.find(filter => !filter.checked);
    },
  },
  methods: {
    buildFilters() {
      const uniqueTypes = Array.from(new Set(this.items.map(item => item[this.filterField])));
      this.filters = uniqueTypes.map(type => ({
        name: type,
        label:
          type.charAt(0).toUpperCase() +
          type
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .slice(1),
        checked: true,
      }));
    },
    updateFiltersFromTypeParam() {
      const typeParam = this.$route.query.type;
      if (typeParam !== undefined) {
        const selectedTypes = new Set(typeParam.split(','));
        this.filters.forEach(filter => (filter.checked = selectedTypes.has(filter.name)));
        this.updateAllCheckbox(); // someone may have manually edited the url to include ALL filter items
      } else {
        this.allChecked = true;
        this.filters.forEach(filter => (filter.checked = true));
      }
      this.filterData();
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    allChanged() {
      this.allChecked = !this.allChecked;
      this.filters.forEach(filter => (filter.checked = this.allChecked));
      this.updateTypeParamFromFilters();
      this.filterData();
    },
    filterChanged(name, checked) {
      this.filters.find(filter => filter.name === name).checked = !checked;
      this.updateAllCheckbox();
      this.updateTypeParamFromFilters();
      this.filterData();
    },
    getSelectedFilters() {
      return this.filters.reduce((acc, filter) => {
        if (filter.checked) {
          acc.add(filter.name);
        }
        return acc;
      }, new Set());
    },
    updateAllCheckbox() {
      this.allChecked = this.getSelectedFilters().size === this.filters.length;
    },
    updateTypeParamFromFilters() {
      let typeParam;
      if (this.allChecked) {
        typeParam = undefined;
      } else {
        typeParam = Array.from(this.getSelectedFilters()).join(',');
      }

      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (query.type !== typeParam) {
        query.type = typeParam;
        this.$router.replace({ query });
      }
    },
    filterData() {
      const selectedFilterSet = this.getSelectedFilters();

      let dataToDisplay = [];

      if (selectedFilterSet.size > 0) {
        dataToDisplay = this.items.filter(item => {
          return selectedFilterSet.has(item[this.filterField]);
        });
      }

      this.setData(dataToDisplay);
    },
    ...mapMutations({
      setData: 'dataFilter/setData',
    }),
  },
  components: {
    Modal,
  },
};
</script>
<style scoped>
#filters .slds-form-element__label {
  padding-left: 9px;
}
#filters label {
  margin: 10px 20px;
}
</style>
