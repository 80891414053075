<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-sobject" title="Snapshots">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#sobject" />
          </svg>
          <span class="slds-assistive-text">Object Automations</span>
        </span>
      </div>
      <div slot="title">
        <span>Object Automations ({{ objectAutomations.length }})</span>
      </div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <DataSearch :items="filteredItems" :columns="columns" />
          </div>
          <div class="slds-page-header__control">
            <DataFilter :items="objectAutomations" filter-field="type" title="Filter by Type" />
          </div>
        </div>
      </div>
      <div slot="body">
        <DataSort :items="searchedItems" :column="sortColumn" :desc="sortDesc" :columns="columns" />
        <DataTable
          type="item"
          :displayRows="currentPage"
          :columns="columns"
          sortable
          :isAlreadySorted="true"
          :sort-column="sortColumn"
          :sort-desc="sortDesc"
          @sort="handleSort"
        >
          <tr class="slds-hint-parent" slot="footer">
            <th>Sum</th>
            <td></td>
            <td></td>
            <th>{{ summary.activeTriggerCount }}</th>
            <th>{{ summary.deactiveTriggerCount }}</th>
            <th>{{ summary.activeWorkflowCount }}</th>
            <th>{{ summary.deactiveWorkflowCount }}</th>
            <th>{{ summary.activeProcessesCount }}</th>
            <th>{{ summary.deactiveProcessesCount }}</th>
            <th>{{ summary.activeFlowCount }}</th>
            <th>{{ summary.deactiveFlowCount }}</th>
            <th>{{ summary.workflowActionCount }}</th>
            <th>{{ summary.fieldUpdateActionCount }}</th>
            <th>{{ summary.activeAutomationTotal }}</th>
            <th>{{ summary.automationTotal }}</th>
          </tr>
        </DataTable>

        <DataPaginate :per-page="100" :items="sortedItems" />
      </div>
    </DetailCard>
  </div>
</template>

<script>
import DetailCard from '../components/DetailCard';
import DataFilter from '../components/DataFilter';
import DataSearch from '../components/DataSearch';
import DataTable from '../components/DataTable';
import DataSort from '../components/DataSort';
import DataPaginate from '../components/DataPaginate';
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      sortColumn: 'label',
      sortDesc: false,
      columns: [
        {
          title: 'id',
          field: 'itemId',
        },
        {
          title: 'Name',
          params: {
            id: 'itemId',
          },
          type: 'item',
          field: 'label',
          width: 200,
        },
        {
          title: 'Api Name',
          field: 'apiName',
        },
        {
          title: 'activeTrigger',
          field: 'activeTriggerCount',
        },
        {
          title: 'deactiveTrigger',
          field: 'deactiveTriggerCount',
        },
        {
          title: 'activeWorkflow',
          field: 'activeWorkflowCount',
        },
        {
          title: 'deactiveWorkflow',
          field: 'deactiveWorkflowCount',
        },
        {
          title: 'activeProcess',
          field: 'activeProcessesCount',
        },
        {
          title: 'deactiveProcess',
          field: 'deactiveProcessesCount',
        },
        {
          title: 'activeFlow',
          field: 'activeFlowCount',
        },
        {
          title: 'deactiveFlow',
          field: 'deactiveFlowCount',
        },
        {
          title: 'workflowAction',
          field: 'workflowActionCount',
        },
        {
          title: 'fieldUpdateAction',
          field: 'fieldUpdateActionCount',
        },
        {
          title: 'Active Total',
          field: 'activeAutomationTotal',
        },
        {
          title: 'Total',
          field: 'automationTotal',
          isHeader: true,
        },
      ],
    };
  },
  async mounted() {
    await this.getScan({ id: this.id });
    await this.getAll(this.id);
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      objectAutomations: state => state.objectAutomations.all,
      filteredItems: state => state.dataFilter.data,
      searchedItems: state => state.dataSearch.data,
      sortedItems: state => state.dataSort.data,
      currentPage: state => state.dataPaginate.data,
    }),
    data() {
      return this.objectAutomations;
    },
    summary() {
      return this.searchedItems.reduce((acc, objAuto) => {
        for (const [key, value] of Object.entries(objAuto)) {
          if (typeof value === 'number') {
            acc[key] = acc[key] ? acc[key] + value : value;
          }
        }
        return acc;
      }, {});
    },
  },
  methods: {
    ...mapActions({ getScan: 'scans/get' }),
    ...mapCacheActions('objectAutomations', ['getAll']),
    handleSort(event) {
      if (event.column.field === this.sortColumn) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = event.column.field;
        this.sortDesc = false;
      }
    },
  },
  components: {
    DataFilter,
    DataSearch,
    DataSort,
    DataTable,
    DataPaginate,
    DetailCard,
  },
};
</script>
<style scoped>
.setup_release_updateReleaseUpdateStatus {
  border: 2px solid rgb(217, 219, 221);
  background-color: #ffffff;
}
.setup_release_updateReleaseUpdateStatus.background-OVERDUE {
  background: rgb(255, 151, 147);
  border: 2px solid rgb(194, 57, 52);
}
.setup_release_updateReleaseUpdateStatus .badge-CRITICAL {
  background-color: rgb(194, 57, 52);
  color: rgb(255, 255, 255);
}
.setup_release_updateReleaseUpdateStatus .slds-icon_container {
  color: rgb(255, 255, 255);
}
</style>
