<template>
  <span>
    <button class="slds-button slds-button_brand" @click="showModal = true" :disabled="disabled">New Snapshot</button>
    <Alert v-if="showModal" @close="closeModal" @ok="takeSnapshot" okBtnText="Take Snapshot" title="New Snapshot">
      <div slot="body" class="slds-p-around_large">
        <fieldset class="slds-form-element slds-grid slds-grid_vertical slds-gutters slds-text-align_center">
          <div class="slds-m-bottom_medium data-robot">
            <img :src="`${publicPath}DataRobot.png`" alt="Robot Image" />
          </div>
          <div>
            <p class="slds-text-title">Start a new Scan</p>
          </div>
        </fieldset>
      </div>
    </Alert>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Alert from './Alert';

export default {
  name: 'TakeSnapshot',
  props: {
    connectionId: { required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      profileName: state => state.auth.user.profile.apiName,
    }),
  },
  methods: {
    ...mapActions({
      getMetadataAndStartScan: 'scans/snapshotScan',
      getSFConnection: 'sfConnections/get',
    }),
    async takeSnapshot() {
      await this.getMetadataAndStartScan({
        connectionId: parseInt(this.connectionId),
      });
      await this.getSFConnection(this.connectionId);
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
  },
  components: {
    Alert,
  },
};
</script>
<style scoped>
.data-robot {
  width: 350px;
  align-self: center;
}
</style>
