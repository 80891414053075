<template>
  <div></div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SalesforceCallback',
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = decodeURIComponent(urlParams.get('code'));
    const state = decodeURIComponent(urlParams.get('state'));

    let isSandbox;
    try {
      isSandbox = JSON.parse(state).isSandbox;
    } catch (e) {
      console.error('Unable to parse state, api call will fail');
      // todo: manually set error state
    }

    await this.doUpsert({ authorizationCode, isSandbox });
    if (!this.error) {
      await this.$router.push({ name: 'connectionSnapshots', params: { id: this.connection.id }, replace: true });
    }
  },
  computed: {
    ...mapState({
      error: state => state.app.error,
      connection: state => state.sfConnections.current,
    }),
  },
  methods: {
    ...mapActions({
      doUpsert: 'sfConnections/upsert',
    }),
  },
};
</script>
