<template>
  <div class="slds-form-element">
    <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right">
      <svg class="slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default" aria-hidden="true">
        <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
      </svg>
      <input
        type="text"
        id="text-input-id-1"
        placeholder="Search this list"
        class="slds-input"
        v-model="searchQuery"
        @keyup="searchQueryChanged"
      />
      <button
        class="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
        title="Clear"
        @click="searchQueryCleared"
      >
        <svg class="slds-button__icon slds-icon-text-light" aria-hidden="true">
          <use xlink:href="@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
        </svg>
        <span class="slds-assistive-text">Clear</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    items: Array,
    columns: Array,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  mounted() {
    this.searchData();
  },
  watch: {
    items() {
      this.searchData();
    },
  },
  methods: {
    ...mapMutations({
      setData: 'dataSearch/setData',
    }),
    searchQueryChanged() {
      // TODO: Debounce?
      this.searchData();
    },
    searchQueryCleared() {
      this.searchQuery = '';
      this.searchData();
    },
    searchData() {
      const searchKey = this.searchQuery && this.searchQuery.toLowerCase();
      let rows = this.items;

      if (searchKey !== '') {
        rows = rows.filter(row => {
          for (let column of this.columns) {
            const cellValue = String(row[column.field]).toLowerCase();
            if (cellValue.indexOf(searchKey) > -1) {
              return true;
            }
          }
          return false;
        });
      }

      this.setData(rows);
    },
  },
};
</script>

<style scoped></style>
