<template>
  <div id="gridView">
    <PageHeader class="slds-m-bottom_small">
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-contact">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#bot" />
          </svg>
        </span>
      </div>
      <div slot="title" v-if="sfConnection">{{ sfConnection.name }}</div>
      <div slot="subtitle">Id: {{ id }}</div>

      <div slot="actions" v-if="sfConnection && showActionButtons(sfConnection)">
        <div class="slds-no-flex slds-button-group" role="group">
          <button
            class="slds-button slds-button_neutral"
            @click="showConnectionEdit(sfConnection)"
            :disabled="actionInProgress"
          >
            Edit
          </button>
          <button
            v-if="!sfConnection.isActive"
            class="slds-button slds-button_neutral"
            @click="handleGetAuthorizationUrl(sfConnection)"
            :disabled="actionInProgress"
          >
            Connect
          </button>
          <button
            v-if="sfConnection.isActive"
            class="slds-button slds-button_neutral"
            @click="handleDisconnect(sfConnection)"
            :disabled="actionInProgress"
          >
            Disconnect
          </button>
          <button
            class="slds-button slds-button_destructive"
            @click="handleDeleteConnection"
            :disabled="actionInProgress"
          >
            Delete
          </button>
        </div>
      </div>
      <div slot="details" v-if="sfConnection">
        <ul class="slds-page-header__detail-row">
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Type</div>
            <div class="slds-truncate" :title="sfConnection.status">
              {{ sfConnection.isSandbox ? 'Sandbox' : 'Production' }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Instance Url</div>
            <div class="slds-truncate" :title="sfConnection.relativePath">
              <a :href="sfConnection.instanceUrl" target="_blank">{{ sfConnection.instanceUrl }}</a>
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Org Id</div>
            <div class="slds-truncate" :title="sfConnection.organizationId">
              {{ sfConnection.organizationId }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Created By</div>
            <div class="slds-truncate" :title="sfConnection.createdBy.name">
              {{ sfConnection.createdBy.name }}
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate">Created Date</div>
            <div class="slds-truncate" :title="sfConnection.createdAt | readableDate">
              {{ sfConnection.createdAt | moment('from') }}
            </div>
          </li>
        </ul>
      </div>
    </PageHeader>
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-opportunity" title="scan">
          <svg class="slds-icon slds-icon_small" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg#custom38" />
          </svg>
          <span class="slds-assistive-text">Snapshots</span>
        </span>
      </div>
      <div slot="title">
        <span>Snapshots ({{ snapshots.length }})</span>
      </div>
      <div slot="actions" v-if="sfConnection">
        <div class="slds-no-flex">
          <button
            class="slds-button slds-button_icon slds-button_icon-border-filled"
            title="Refresh List"
            @click="getSFConnection(sfConnection.id)"
            :disabled="actionInProgress"
          >
            <svg class="slds-button__icon" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
            </svg>
            <span class="slds-assistive-text">Refresh List</span>
          </button>
          <TakeSnapshot
            v-if="showActionButtons(sfConnection)"
            :connectionId="id"
            :disabled="actionInProgress"
            class="slds-m-left_xx-small"
          />
        </div>
      </div>
      <div slot="body">
        <DataTable
          type="snapshot"
          :displayRows="snapshotRowData"
          :columns="[
            {
              title: 'Id',
              field: 'id',
            },
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Status',
              field: 'status',
              width: 120,
            },
            {
              title: 'Created By',
              field: 'createdBy',
              width: 130,
            },
            {
              title: 'Created At',
              field: 'createdAt',
            },
            {
              title: 'Completed At',
              field: 'completedAt',
            },
          ]"
          :showActions="showActions()"
          v-on:delete="handleDeleteSnapshot"
          v-on:rename="handleRename"
        />
      </div>
    </DetailCard>
    <SnapshotEditor
      v-if="snapshotEditorVisible"
      :show="snapshotEditorVisible"
      :snapshot="snapshotToEdit"
      @close="handleSnapshotEditorClose"
    />
    <form novalidate>
      <Modal :title="'Salesforce Connection'" v-if="showEditConnectionModal" @close="handleClose">
        <div slot="body">
          <div class="slds-form">
            <InputComponent
              v-model="form.name"
              :v="$v.form.name"
              :name="sfConnection.name"
              label="Name"
              placeholder="Salesforce Connection Name"
            />
          </div>
        </div>
        <div slot="footer" style="margin-top: 10px">
          <button class="slds-button slds-button_brand" @click.prevent="submit" :disabled="$v.form.$invalid">
            Save
          </button>
          <button type="reset" class="slds-button slds-button_neutral" @click="handleCancel">Cancel</button>
        </div>
      </Modal>
    </form>
    <DisconnectConnectionConfirm
      :show="showDisconnectConfirmationModal"
      :selectedSnapshotId="selectedSnapshotId"
      :selectedSnapshotName="selectedSnapshotName"
      @close-model="hideDisconnectConfirmationModal"
    />
  </div>
</template>

<script>
import DataTable from '../components/DataTable';
import DetailCard from '../components/DetailCard';
import Modal from '../components/Modal';
import notification from '../utils/notification';
import PageHeader from '../components/PageHeader';
import SnapshotEditor from '../components/SnapshotEditor';
import TakeSnapshot from '../components/TakeSnapshot';
import DisconnectConnectionConfirm from '../components/DisconnectConnectionConfirm';
import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';

import { helpers, maxLength, minLength, required } from 'vuelidate/lib/validators';
import InputComponent from '../components/InputComponent.vue';

const alphaNumAndDotValidator = helpers.regex('alphaNumAndDotValidator', /^[A-Za-z\d\-_\s\\(\\)]+$/);

export default {
  props: {
    id: { required: true },
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(80),
        alphaNumAndDotValidator,
        consecutiveWhitespace(value) {
          if (value === '') {
            return true;
          }

          const res = new RegExp(/\s{2,}/).test(value);
          return !res;
        },
      },
    },
  },
  async mounted() {
    await this.getSFConnection(this.id);
    const urlParams = new URLSearchParams(window.location.search);
    let error = urlParams.get('error');
    if (error) {
      notification.error(this, error, `Please ensure you are using the correct credentials`);
    }
  },
  data() {
    return {
      selectedSnapshotName: '',
      selectedSnapshotId: null,
      showDisconnectConfirmationModal: false,
      showEditConnectionModal: false,
      snapshotToEdit: null,
      snapshotEditorVisible: false,
      form: {
        name: '',
      },
      user: this.$auth.user,
    };
  },
  methods: {
    ...mapActions({
      deleteSFConnection: 'sfConnections/delete',
      deleteSnapshot: 'snapshots/delete',
      edit: 'sfConnections/edit',
      getAuthorizationUrl: 'sfConnections/getAuthorizationUrl',
      getSFConnection: 'sfConnections/get',
    }),
    async submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      await this.edit({
        id: this.sfConnection.id,
        data: this.form,
      });
      notification.success(this, 'Edit', `Connection edited successfully!`);
      await this.getSFConnection(this.id);
      this.handleClose(true);
    },
    async handleDeleteConnection() {
      await this.deleteSFConnection(this.id);
      await this.$router.push({ name: 'snapshots' });
    },
    async handleDeleteSnapshot(event) {
      await this.deleteSnapshot({ id: event.record.id });
      await this.getSFConnection(this.id);
    },
    async handleSnapshotEditorClose(event) {
      this.snapshotEditorVisible = false;
      this.snapshotToEdit = null;
      if (event.saved) {
        await this.getSFConnection(this.id);
      }
    },
    async hideDisconnectConfirmationModal(data) {
      if (data.state === 'deleted') {
        await this.getSFConnection(this.selectedSnapshotId);
      }
      this.showDisconnectConfirmationModal = false;
    },
    showActionButtons(sfConnection) {
      return !(
        this.user &&
        sfConnection.createdBy &&
        sfConnection.createdBy.username !== this.$auth.user.username &&
        this.$auth.user.profile === 'readOnlyAdmin'
      );
    },
    showActions() {
      return true;
    },
    handleRename(event) {
      this.snapshotToEdit = event.record;
      this.snapshotEditorVisible = true;
    },
    showConnectionEdit(sfConnection) {
      this.showEditConnectionModal = true;
      this.form.name = sfConnection.name;
    },
    handleDisconnect(sfConnection) {
      this.selectedSnapshotId = sfConnection.id.toString();
      this.selectedSnapshotName = sfConnection.name;
      this.showDisconnectConfirmationModal = true;
    },
    handleClose(saved) {
      this.showEditConnectionModal = false;
      this.$emit('close', { saved });
    },
    handleCancel() {
      this.showEditConnectionModal = false;
      this.handleClose();
    },
    handleGetAuthorizationUrl(connectionDetails) {
      let formDetail = {};
      formDetail.name = connectionDetails.name;
      formDetail.isReconnect = true;
      formDetail.orgId = connectionDetails.organizationId;
      formDetail.id = connectionDetails.id;
      formDetail.isSandbox = connectionDetails.isSandbox;
      this.getAuthorizationUrl(formDetail);
    },
  },
  computed: {
    ...mapState({
      snapshots(state) {
        if (!state.sfConnections.current || !state.sfConnections.current.metadata) {
          return [];
        }
        const snapshots = sortBy(state.sfConnections.current.metadata, ['id']);
        return snapshots.map(snapshot => {
          return {
            ...snapshot,
            allowAction: this.$auth.user.profile === 'sysadmin' || snapshot.owner.username === this.$auth.user.username,
            actions: this.defaultActions,
          };
        });
      },
      sfConnection: state => state.sfConnections.current,
      actionInProgress: state => state.app.actionInProgress,
      defaultActions() {
        let result = [{ label: 'Rename', name: 'rename' }];
        if (this.$auth.user.profile === 'sysadmin') {
          result.push({ label: 'Delete', name: 'delete' });
        }
        return result;
      },
    }),
    snapshotRowData: {
      get() {
        return this.snapshots;
      },
    },
  },
  components: {
    DataTable,
    DetailCard,
    DisconnectConnectionConfirm,
    InputComponent,
    Modal,
    PageHeader,
    SnapshotEditor,
    TakeSnapshot,
  },
};
</script>
