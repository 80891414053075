<template>
  <StaticInformationBox type="error">
    <span slot="title">No Access</span>
    <div slot="body">
      You do not have permissions to access the console. <br />
      Please log out and log in with a user with the correct permissions.
    </div>
  </StaticInformationBox>
</template>

<script>
import StaticInformationBox from '@/components/StaticInformationBox.vue';

export default {
  name: 'NoAccess',
  components: { StaticInformationBox },
};
</script>
