<template>
  <div class="slds-grid slds-gutters slds-wrap">
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.securityHealthScore">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-practitioner-role" title="Security Health Score">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#service_request"
              />
            </svg>
            <span class="slds-assistive-text">Security Health Score</span>
          </span>
        </div>
        <div slot="title">
          <span>Security Health Score</span>
        </div>
        <div slot="actions">
          <div class="flex-wrapper">
            <div class="single-chart">
              <svg
                viewBox="0 0 36 36"
                class="circular-chart"
                v-bind:class="{
                  orange: scan.securityHealthScore >= 40 && scan.securityHealthScore <= 90,
                  green: scan.securityHealthScore > 90,
                  red: scan.securityHealthScore < 40,
                }"
              >
                <path
                  class="circle-bg"
                  d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  class="circle"
                  :stroke-dasharray="scan.securityHealthScore + ', 100'"
                  d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">{{ scan.securityHealthScore }}%</text>
              </svg>
            </div>
          </div>
        </div>
      </DetailCard>
      <DetailCard
        class="slds-m-bottom_small"
        v-if="scan.securityHealthCheckRiskCount && scan.securityHealthCheckRiskCount.length > 0"
      >
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-practitioner-role" title="Security Health Risks">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#service_request_detail"
              />
            </svg>
            <span class="slds-assistive-text">Security Health Risks</span>
          </span>
        </div>
        <div slot="title">
          <span>Security Health Risks</span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'securityHealthCheckRisks' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <table
          slot="body"
          class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
          role="grid"
        >
          <thead>
            <tr class="slds-line-height_reset">
              <th class="" scope="col">
                <div class="slds-truncate" title="Name">Risk Type</div>
              </th>
              <th class="" scope="col">
                <div class="slds-truncate slds-text-align_right" title="Company">Count</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="slds-hint-parent" v-for="entry in scan.securityHealthCheckRiskCount" :key="entry.type">
              <th scope="row">
                <div class="slds-truncate" :title="entry.type">
                  <router-link
                    :to="{
                      name: 'securityHealthCheckRisks',
                      query: { type: entry.type },
                    }"
                    >{{ entry.type | humanize }}</router-link
                  >
                </div>
              </th>
              <td role="gridcell" class="slds-text-align_right">
                {{ entry.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailCard>
      <DetailCard class="slds-m-bottom_small" v-if="scan.permissionSets && scan.permissionSets.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-work-type-group" title="Permission Sets">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#work_type_group"
              />
            </svg>
            <span class="slds-assistive-text">Permission Sets</span>
          </span>
        </div>
        <div slot="title">
          <span>Permission Sets <span style="font-size: 10px; font-weight: 400">(Top 5)</span></span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'permissionSets' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">User Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.permissionSets" :key="entry.type">
                <th scope="row">
                  <div class="slds-truncate" :title="entry.type">
                    {{ entry.type | humanize }}
                  </div>
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
      <DetailCard class="slds-m-bottom_small" v-if="scan.riskyPermissionSets && scan.riskyPermissionSets.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-quip" title="Risky Permission Sets">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#work_type_group"
              />
            </svg>
            <span class="slds-assistive-text">Risky Permission Sets</span>
          </span>
        </div>
        <div slot="title">
          <span>Risky Permission Sets </span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'permissionSets' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>

        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">User Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.riskyPermissionSets" :key="entry.type">
                <th scope="row">
                  <router-link
                    :to="{
                      name: 'permissionSets',
                      query: { type: entry.type },
                    }"
                    ><div class="slds-truncate" :title="entry.type">
                      {{ entry.type | humanize }}
                    </div></router-link
                  >
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.groups && scan.groups.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-groups" title="Groups">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#groups" />
            </svg>
            <span class="slds-assistive-text">Groups</span>
          </span>
        </div>
        <div slot="title">
          <span>Groups <span style="font-size: 10px; font-weight: 400">(Top 5)</span></span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'groups' }" role="button" class="slds-button slds-button_neutral">View</router-link>
        </div>

        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">User Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.groups" :key="entry.type">
                <th scope="row">
                  <div class="slds-truncate" :title="entry.type">
                    {{ entry.type | humanize }}
                  </div>
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.roles && scan.roles.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-user-role" title="Roles">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#user_role" />
            </svg>
            <span class="slds-assistive-text">Roles</span>
          </span>
        </div>
        <div slot="title">
          <span>Roles <span style="font-size: 10px; font-weight: 400">(Top 5)</span></span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'roles' }" role="button" class="slds-button slds-button_neutral">View</router-link>
        </div>
        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">User Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.roles" :key="entry.type">
                <th scope="row">
                  <div class="slds-truncate" :title="entry.type">
                    {{ entry.type | humanize }}
                  </div>
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
      <DetailCard class="slds-m-bottom_small" v-if="scan.sfProfiles && scan.sfProfiles.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-job-profile" title="Profiles">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#job_profile"
              />
            </svg>
            <span class="slds-assistive-text">Profiles</span>
          </span>
        </div>
        <div slot="title">
          <span>Profiles <span style="font-size: 10px; font-weight: 400">(Top 5)</span></span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'sfProfiles' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>
        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">User Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.sfProfiles" :key="entry.type">
                <th scope="row">
                  <div class="slds-truncate" :title="entry.type">
                    {{ entry.type | humanize }}
                  </div>
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.riskyProfiles && scan.riskyProfiles.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-quip" title="Profiles">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#job_profile"
              />
            </svg>
            <span class="slds-assistive-text">Profiles</span>
          </span>
        </div>
        <div slot="title">
          <span>Risky Profiles</span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'sfProfiles' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>
        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">User Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.riskyProfiles" :key="entry.type">
                <th scope="row">
                  <router-link
                    :to="{
                      name: 'sfProfiles',
                      query: { type: entry.type },
                    }"
                    ><div class="slds-truncate" :title="entry.type">
                      {{ entry.type | humanize }}
                    </div></router-link
                  >
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
    </div>
  </div>
</template>
<script>
import DetailCard from './DetailCard';
export default {
  props: {
    scan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {
    DetailCard,
  },
};
</script>
