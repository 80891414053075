<template>
  <DetailCard v-if="snapshot">
    <div slot="icon">
      <span class="slds-icon_container slds-icon-standard-feed" title="Snapshot item">
        <svg class="slds-icon slds-icon_small" aria-hidden="true">
          <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#feed" />
        </svg>
        <span class="slds-assistive-text">Snapshot item</span>
      </span>
    </div>
    <div slot="title">
      <span>Snapshot Progress Log {{ snapshot.status }}</span>
    </div>
    <div slot="actions">
      <button
        class="slds-button slds-button_icon slds-button_icon-border-filled"
        title="Refresh List"
        @click="handleRefresh"
      >
        <svg class="slds-button__icon" aria-hidden="true">
          <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
        </svg>
        <span class="slds-assistive-text">Refresh List</span>
      </button>
    </div>
    <div slot="body" class="slds-card__body_inner slds-p-bottom_small">
      <pre class="slds-text-font_monospace slds-text-body_small">{{ snapshot.log }}</pre>
    </div>
  </DetailCard>
</template>
<script>
import DetailCard from './DetailCard';
import { mapActions } from 'vuex';
export default {
  props: {
    snapshot: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({
      refreshSnapshotLogs: 'snapshots/get',
    }),
    async handleRefresh() {
      await this.refreshSnapshotLogs(this.snapshot.id);
    },
  },
  components: {
    DetailCard,
  },
};
</script>
