<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-user-role" title="Roles">
          <svg class="slds-icon slds-icon_small" aria-hidden="true">
            <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#user_role" />
          </svg>
          <span class="slds-assistive-text">Roles</span>
        </span>
      </div>
      <div slot="title">
        <span>Roles ({{ roles.length }})</span>
      </div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <DataSearch :items="roles" :columns="columns" />
          </div>
        </div>
      </div>
      <div slot="body">
        <TreeGrid type="item" :displayRows="searchedItems" :columns="columns"> </TreeGrid>

        <DataPaginate :per-page="100" :items="sortedItems" />
      </div>
    </DetailCard>
  </div>
</template>

<script>
import DetailCard from '../components/DetailCard';
import DataSearch from '../components/DataSearch';
import TreeGrid from '../components/TreeGrid';
import DataPaginate from '../components/DataPaginate';
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      columns: [
        { title: 'id', field: 'itemId', width: 100 },
        {
          title: 'Name',
          field: 'name',
          wrap: true,
        },
        {
          title: 'description',
          field: 'description',
          wrap: true,
        },
        {
          title: 'caseAccessLevel',
          field: 'caseAccessLevel',
          width: 100,
        },
        {
          title: 'mayForecastManagerShare',
          field: 'mayForecastManagerShare',
          width: 100,
        },
        {
          title: 'opportunityAccessLevel',
          field: 'opportunityAccessLevel',
          width: 100,
        },
        {
          title: 'contactAccessLevel',
          field: 'contactAccessLevel',
          width: 100,
        },

        { title: 'User Count', field: 'count', width: 200 },
      ],
    };
  },
  async mounted() {
    await this.getScan({ id: this.id });
    await this.getAll(this.id);
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      roles: state => state.roles.all,
      filteredItems: state => state.dataFilter.data,
      searchedItems: state => state.dataSearch.data,
      sortedItems: state => state.dataSort.data,
      currentPage: state => state.dataPaginate.data,
    }),
    data() {
      return this.roles;
    },
  },
  methods: {
    ...mapActions({ getScan: 'scans/get' }),
    ...mapCacheActions('roles', ['getAll']),
    handleSort(event) {
      if (event.column === this.sortColumn) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = event.column;
        this.sortDesc = false;
      }
    },
  },
  components: {
    DataSearch,
    DataPaginate,
    DetailCard,
    TreeGrid,
  },
};
</script>
