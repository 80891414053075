<template>
  <div class="slds-grid slds-gutters slds-wrap">
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-code-playground" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use
                xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#code_playground"
              />
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Code Metrics</span>
        </div>
        <div slot="body" class="slds-p-bottom_small">
          <h2 class="slds-section-title slds-p-left_small">Code Counts</h2>
          <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Type</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Name">Source</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">Total</div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.codeCounts" :key="entry.type">
                <td>{{ entry.type | humanize }}</td>
                <td class="slds-text-align_right">{{ entry.source }}</td>
                <td class="slds-text-align_right">{{ entry.total }}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div class="slds-media slds-media_center slds-has-flexi-truncate sub-heading">
            <div class="slds-media__body">
              <h2 class="slds-card__header-title">PMD Code Issues</h2>
            </div>
            <div class="slds-no-flex">
              <router-link :to="{ name: 'pmds' }" role="button" class="slds-button slds-button_neutral"
                >View</router-link
              >
            </div>
          </div>

          <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Issue Type</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">Total</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in scan.pmds" :key="entry.ruleset">
                <td>
                  <router-link
                    :to="{
                      name: 'pmds',
                      query: { type: entry.ruleset },
                    }"
                    >{{ entry.ruleset | humanize }}</router-link
                  >
                </td>
                <td class="slds-text-align_right">{{ entry.count }}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div class="slds-media slds-media_center slds-has-flexi-truncate sub-heading">
            <div class="slds-media__body">
              <h2 class="slds-card__header-title">ESLint Code Issues</h2>
            </div>
            <div class="slds-no-flex">
              <router-link :to="{ name: 'eslints' }" role="button" class="slds-button slds-button_neutral"
                >View</router-link
              >
            </div>
          </div>

          <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Issue Type</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">Total</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in scan.esLints" :key="entry.category">
                <td>
                  <router-link
                    :to="{
                      name: 'eslints',
                      query: { type: entry.category },
                    }"
                    >{{ entry.category | humanize }}</router-link
                  >
                </td>
                <td class="slds-text-align_right">{{ entry.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
    </div>
  </div>
</template>
<script>
import DetailCard from './DetailCard';

export default {
  props: {
    scan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {
    DetailCard,
  },
};
</script>
