<template>
  <div class="slds-grid slds-gutters slds-wrap slds-grid_vertical-stretch" style="margin-bottom: 15px">
    <div class="panel-container slds-col slds-size_1-of-2" v-for="entry in systemOverviewsGroups" :key="entry">
      <span>
        <div class="panel">
          <div class="top-line">
            <h2>{{ entry }}</h2>
          </div>
          <div class="content">
            <div class="panelContent">
              <div
                class="panel-content-item"
                v-bind:class="{
                  'border-top': index !== 0,
                  'usage-warn': entryItem.isWarning,
                }"
                v-for="(entryItem, index) in groupedSystemOverviews[entry]"
                :key="entryItem.id"
              >
                <div class="panelLeft">
                  <div class="type">
                    <span class="title">{{ entryItem.name }}</span>
                  </div>
                  <div class="datalink">
                    <div class="num" id="usage_block_schema_num_1">
                      {{ entryItem.value }}
                      <span class="valueSubscript"></span>
                    </div>
                  </div>
                  <div class="floatClear"></div>
                </div>
                <div class="panelRight">
                  <div class="visual">
                    <div
                      v-if="entryItem.percentage !== null"
                      class="slds-progress-bar progress-bar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :aria-valuenow="entryItem.percentage"
                      role="progressbar"
                    >
                      <span
                        class="slds-progress-bar__value"
                        v-bind:style="{
                          width: (entryItem.percentage > 100 ? 100 : entryItem.percentage) + '%',
                        }"
                        v-bind:class="{
                          'slds-progress-bar__value_success': !entryItem.isWarning,
                          'slds-progress-bar__value_error': entryItem.isWarning,
                        }"
                      >
                        <span class="slds-assistive-text">Progress: {{ entryItem.percentage }}%</span>
                      </span>
                    </div>
                  </div>
                  <span>
                    <div align="right" class="desc">
                      <span class="desc-num">{{ entryItem.message }}</span
                      ><br /></div
                  ></span>
                </div>
                <div class="floatClear"></div>
              </div>
            </div>
          </div></div
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    systemOverviewRecords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const groupedSystemOverviews = this.systemOverviewRecords.reduce((acc, obj) => {
      const key = obj.type;
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
    return {
      groupedSystemOverviews,
      systemOverviewsGroups: Object.keys(groupedSystemOverviews),
    };
  },
};
</script>
<style scoped>
.panel-container {
  margin: 0 0 15px 0;
}
.panel {
  width: 100%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  overflow: visible;
  background-color: #fff;
  height: calc(100% - 2px);
}

.slds-progress-bar__value_error {
  background-color: #da0000;
}

.links-block-top-margin {
  margin-top: 20px;
  margin-right: 20px;
}

.progress-bar {
  width: 120px;
  float: right;
  margin-top: 10px;
  height: 11px;
}

.panel .top-line {
  -moz-border-radius-topleft: 5px;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #dedede;
  border: 1px solid #ccc;
  height: 32px;
}

.panel h2 {
  font-size: 1.083em;
  font-weight: bold;
  padding-top: 9px;
  padding-left: 10px;
  float: left;
}

.panel .headerLink {
  color: #015ba7;
  font-size: 1em;
  text-decoration: none;
  float: right;
  padding-right: 18px;
  padding-top: 9px;
}

.panel .content {
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: inherit;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.relatedLinks {
  background-color: #f8f8f9;
  clear: both;
  border-top: 1px solid #ccc;
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.panel .relatedLinks .top-line {
  background-color: #f8f8f9;
  border: none;
}

.relatedLinks .top-line .header {
  padding: 0 0 0 0;
  color: #666;
  font-size: 0.917em;
  font-weight: bold;
  margin-bottom: 2px;
  margin-left: 15px;
  padding-top: 10px;
}

.relatedLinks .links {
  margin-left: 15px;
  margin-top: 3px;
  padding-bottom: 15px;
  line-height: 20px;
}

.links a,
.relatedLinks .links:visited {
  color: #015ba7;
  text-decoration: none;
  font-size: 1.083em;
}

.panelContent {
  width: 100%;
  background-color: #fff;
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.panel-content-item {
  min-height: 60px;
  position: relative;
  overflow: visible;
}

.usage-warn {
  background-color: #fffedd;
}

.usage-warn-last {
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.panel-content-item .type {
  color: #666;
  padding-left: 10px;
  padding-top: 12px;
  font-size: 0.917em;
  font-weight: normal;
}

.panel-content-item .type .title {
  text-transform: uppercase;
}

.panel-content-item .visual {
  height: 20px;
  padding-left: 15px;
  margin: 5px 0 10px 0;
}

.panel-content-item .num {
  height: 20px;
  color: #015ba7;
  font-size: 14px;
  padding-top: 5px;
  padding-left: 10px;
  font-weight: 600;
  float: left;
}

.panel-content-item .textOnly {
  color: #7d7d84;
}

.panel-content-item .error a {
  text-decoration: none;
  color: #015ba7;
}

.panel-content-item .num a {
  text-decoration: none;
  color: #015ba7;
}

.links a,
.relatedLinks .links {
  color: #015ba7;
  text-decoration: none;
  font-size: 1em;
}

.panel-content-item .desc {
  height: 20px;
  color: #7d7d84;
  padding-left: 15px;
  font-size: 0.917em;
  font-style: italic;
}

.panel-content-item .desc .desc-num {
  height: 20px;
  color: #666;
  color: #666;
  padding-left: 15px;
  font-style: normal;
}

.panel-content-item .error {
  height: 20px;
  color: #7d7d84;
  padding-left: 15px;
  font-size: 0.917em;
  font-style: italic;
}

.panel-content-item .error .desc-num {
  height: 20px;
  color: #666;
  color: #666;
  padding-left: 15px;
  font-style: normal;
}

.bar-na {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  height: 12px;
  float: left;
  text-align: center;
  color: #777;
}

.bar-positive {
  background-color: #65a612;
  border-bottom: 1px solid #65a612;
  border-top: 1px solid #457507;
  border-left: 1px solid #65a612;
  z-index: 5;
  height: 12px;
  float: left;
}

.bar-positive-warn {
  background-color: #da0000;
  border-bottom: 1px solid #da0000;
  border-top: 1px solid #870a0a;
  border-left: 1px solid #da0000;
}

.bar-negative {
  background-color: #e4e4e4;
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-top: 1px solid #777;
  height: 12px;
  float: left;
}

.bar {
  margin-top: 7px;
  width: 105px;
  float: right;
}

.set-panel-horizontal {
  background-color: #f8f8f9;
}

.set-info-horizontal {
  float: left;
  width: 370px;
  border-right: 1px solid #ccc;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

.set-links-horizontal {
  padding-top: 0;
  margin-left: 370px;
  clear: none;
  border-top: none;
}

.panelRight {
  float: right;
  padding-right: 10px;
}

.datalink {
  margin-top: 2px;
}

.datalink .valueSubscript {
  color: #666;
  font-size: 0.75em;
  font-weight: normal;
  vertical-align: 0.075em;
}

.border-top {
  border-top: 1px solid #ccc;
}

.warning-num {
  color: #666;
  font-weight: bold;
}

.warning-icon {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

#bodyCell {
  background: url('/img/in-page_clouds.png') no-repeat #fff;
  height: 234px;
}

.bar-container {
  float: right;
}

#HomepageSettingDialog a {
  color: #015ba7;
  text-decoration: none;
}

#HomepageSettingDialog .instruction {
  width: 65%;
  float: left;
  padding-top: 15px;
}

#HomepageSettingDialog .launchButton {
  float: right;
  margin-top: 15px;
  margin-right: 25px;
}

#dialogContent .dialogFirst {
  font-weight: bold;
}

#dialogContent .blockInput {
  padding: 4px 0 2px 0;
}

.dualPanelClear,
.floatClear {
  clear: both;
}

.panelLeft {
  float: left;
}

.panel-content-item .infoIcon {
  margin-left: 5px;
  vertical-align: middle;
  text-transform: none;
}
</style>
