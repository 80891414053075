import api from '../../utils/api';

const state = () => ({
  all: [],
  current: null,
});

const getters = {};

const actions = {
  async getAll({ commit }) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const connections = await api.get({
        path: '/oauth/salesforce/connections',
      });
      commit('setAll', connections);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async get({ commit }, id) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const connection = await api.get({
        path: `/oauth/salesforce/connections/${id}`,
      });
      commit('setCurrent', connection);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async getAuthorizationUrl({ commit }, formData) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const { name, isSandbox, isReconnect, orgId, id = null } = formData;
      const isSandboxParam = isSandbox ? '&sandbox=true' : '';
      const isReconnectParam = isReconnect ? '&isReconnect=true' : '';
      const orgIdParam = orgId ? '&orgId=' + orgId : '';
      const idParam = id ? '&id=' + id : '';
      const { authorizationUrl } = await api.get({
        path: `/oauth/salesforce?name=${name}${isSandboxParam}${isReconnectParam}${orgIdParam}${idParam}`,
      });
      window.location.href = authorizationUrl;
    } catch (error) {
      commit('app/setError', error, { root: true });
      commit('app/clearActionInProgress', null, { root: true });
    }
  },

  async upsert({ commit }, data) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const response = await api.put({ path: '/oauth/salesforce/connections', body: data });
      commit('setCurrent', response.data);
    } catch (error) {
      console.log(JSON.stringify(error));
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  },

  async edit({ commit, dispatch }, { id, data, deleteAuth = false }) {
    commit('app/setActionInProgress', null, { root: true });
    let response;
    let path = `/oauth/salesforce/connections/${id}`;
    try {
      if (deleteAuth) {
        path += '?deleteAuth=true';
      }
      response = await api.put({
        path: path,
        body: data,
      });
      dispatch('getAll');
    } catch (error) {
      response = { error };
    }
    commit('app/clearActionInProgress', null, { root: true });
    return response;
  },

  async delete({ commit, dispatch }, id) {
    commit('app/setActionInProgress', null, { root: true });
    try {
      await api.delete({
        path: `/oauth/salesforce/connections/${id}?force=true`,
      });
      dispatch('getAll');
      commit('app/clearActionInProgress', null, { root: true });
    } catch (error) {
      commit('app/setError', error, { root: true });
      commit('app/clearActionInProgress', null, { root: true });
      throw error;
    }
  },
};

const mutations = {
  setAll(state, all) {
    state.all = all;
  },
  setCurrent(state, current) {
    state.current = current;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
