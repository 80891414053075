<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-employee-organization" title="Groups">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use
              xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#employee_organization"
            />
          </svg>
          <span class="slds-assistive-text">Groups</span>
        </span>
      </div>
      <div slot="title">
        <span>Groups ({{ groups.length }})</span>
      </div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <DataSearch :items="groups" :columns="columns" />
          </div>
        </div>
      </div>
      <div slot="body">
        <DataSort :items="searchedItems" :column="sortColumn" :desc="sortDesc" :columns="columns" />
        <DataTable
          :displayRows="currentPage"
          :columns="columns"
          sortable
          :isAlreadySorted="true"
          :sort-column="sortColumn"
          :sort-desc="sortDesc"
          @sort="handleSort"
        />

        <DataPaginate :per-page="100" :items="sortedItems" />
      </div>
    </DetailCard>
  </div>
</template>

<script>
import DetailCard from '../components/DetailCard';
import DataSearch from '../components/DataSearch';
import DataTable from '../components/DataTable';
import DataSort from '../components/DataSort';
import DataPaginate from '../components/DataPaginate';
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      columns: [
        { title: 'Id', field: 'id' },
        {
          title: 'Label',
          field: 'label',
          wrap: true,
        },
        {
          title: 'Name',
          field: 'name',
          wrap: true,
        },
        { title: 'User Count', field: 'count', width: 400 },
      ],
      sortColumn: 'id',
      sortDesc: false,
      currentRecord: null,
      type: 'Group',
    };
  },
  async mounted() {
    await this.getScan({ id: this.id });
    await this.getAll(this.id);
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      groups: state => state.groups.all,
      filteredItems: state => state.dataFilter.data,
      searchedItems: state => state.dataSearch.data,
      sortedItems: state => state.dataSort.data,
      currentPage: state => state.dataPaginate.data,
    }),
    data() {
      return this.groups;
    },
  },
  methods: {
    ...mapActions({ getScan: 'scans/get' }),
    ...mapCacheActions('groups', ['getAll']),
    handleSort(event) {
      if (event.column.field === this.sortColumn) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = event.column.field;
        this.sortDesc = false;
      }
    },
  },
  components: {
    DataSearch,
    DataSort,
    DataTable,
    DataPaginate,
    DetailCard,
  },
};
</script>
