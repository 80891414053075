<template>
  <header class="header_container" style="z-index: 5001">
    <div class="slds-context-bar">
      <div class="slds-context-bar__primary">
        <div class="slds-context-bar__item slds-no-hover">
          <span class="slds-context-bar__label-action slds-context-bar__app-name">
            <img class="slds-show_inline-block xray-logo_small" :src="`${publicPath}hubbl_menu_logo.png`" />
            <span class="slds-show_inline-block" style="font-size: 12px">{{ packageVersion }}</span>
          </span>
        </div>
      </div>
      <nav class="slds-context-bar__secondary" role="navigation">
        <ul class="slds-grid">
          <li
            v-for="navItem in navItems"
            :key="navItem.name"
            class="slds-context-bar__item"
            :class="{
              'slds-is-active': currentRoute.name === navItem.name || currentRoute.path.startsWith(navItem.path),
            }"
          >
            <router-link :to="{ name: navItem.name }" class="slds-context-bar__label-action">
              <span
                :v-if="currentRoute.name === navItem.name || currentRoute.path.startsWith(navItem.path)"
                class="slds-assistive-text"
                >Current Page:</span
              >
              <span>{{ navItem.label }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="slds-global-header__item slds-align_absolute-center">
        <ul class="slds-global-actions">
          <li class="slds-global-actions__item">
            <UserMenu />
          </li>
        </ul>
      </div>
    </div>

    <Breadcrumbs />
  </header>
</template>

<script>
import Breadcrumbs from './Breadcrumbs';
import UserMenu from './UserMenu';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      navItems: [
        { name: 'scans', label: 'Scans', path: '/scans' },
        { name: 'snapshots', label: 'Snapshots', path: '/snapshots' },
      ],
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    ...mapState({
      packageVersion: state => state.app.packageVersion,
    }),
    currentRoute() {
      return this.$route;
    },
  },
  components: {
    Breadcrumbs,
    UserMenu,
  },
};
</script>

<style scoped>
.header_container {
  position: sticky;
  top: 0;
  width: 100%;
}
.xray-logo_small {
  height: 50px;
  margin: 5px 10px 5px 0;
}
</style>
