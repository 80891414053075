<template>
  <div class="slds-grid slds-gutters slds-wrap">
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-sobject" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#sobject" />
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Object Metrics</span>
        </div>
        <div slot="body" class="slds-p-bottom_small">
          <div class="slds-media slds-media_center slds-has-flexi-truncate sub-heading">
            <div class="slds-media__body">
              <h2 class="slds-card__header-title">Field Counts - Top 5</h2>
            </div>
            <div class="slds-no-flex">
              <router-link :to="{ name: 'objectFieldCounts' }" role="button" class="slds-button slds-button_neutral"
                >View</router-link
              >
            </div>
          </div>

          <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">SObject Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Field Count">Field Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in scan.fieldCounts" :key="entry.label">
                <td>{{ entry.label }}</td>
                <td class="slds-text-align_right">{{ entry.count }}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div v-if="scan.triggerCounts && scan.triggerCounts.length > 0">
            <div class="slds-media slds-media_center slds-has-flexi-truncate sub-heading">
              <div class="slds-media__body">
                <h2 class="slds-card__header-title">Automation - Top 5</h2>
              </div>
              <div class="slds-no-flex">
                <router-link :to="{ name: 'objectAutomations' }" role="button" class="slds-button slds-button_neutral"
                  >View</router-link
                >
              </div>
            </div>
            <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
              <thead>
                <tr class="slds-line-height_reset">
                  <th class="" scope="col">
                    <div class="slds-truncate" title="Name">SObject Name</div>
                  </th>
                  <th class="" scope="col">
                    <div class="slds-truncate slds-text-align_right" title="Company">Trigger Count</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="slds-hint-parent" v-for="entry in scan.triggerCounts" :key="entry.name">
                  <th scope="row">
                    <div class="slds-truncate" :title="entry.name">
                      {{ entry.name }}
                    </div>
                  </th>
                  <td role="gridcell" class="slds-text-align_right">
                    {{ entry.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div v-if="scan.sharingRules && scan.sharingRules.length > 0">
            <div class="slds-media slds-media_center slds-has-flexi-truncate sub-heading">
              <div class="slds-media__body">
                <h2 class="slds-card__header-title">Sharing Rules - Top 5</h2>
              </div>
              <div class="slds-no-flex">
                <router-link :to="{ name: 'sharingRules' }" role="button" class="slds-button slds-button_neutral"
                  >View</router-link
                >
              </div>
            </div>
            <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
              <thead>
                <tr class="slds-line-height_reset">
                  <th class="" scope="col">
                    <div class="slds-truncate" title="Name">SObject Name</div>
                  </th>
                  <th class="" scope="col">
                    <div class="slds-truncate slds-text-align_right" title="Company">Sharing Rule Count</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="slds-hint-parent" v-for="entry in scan.sharingRules" :key="entry.objectName">
                  <th scope="row">
                    <div class="slds-truncate" :title="entry.objectName">
                      {{ entry.objectName }}
                    </div>
                  </th>
                  <td role="gridcell" class="slds-text-align_right">
                    {{ entry.total }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div v-if="scan.approvalProcessCounts && scan.approvalProcessCounts.length > 0">
            <div class="slds-media slds-media_center slds-has-flexi-truncate sub-heading">
              <div class="slds-media__body">
                <h2 class="slds-card__header-title">Approval Process - Top 5</h2>
              </div>
              <div class="slds-no-flex">
                <router-link
                  :to="{ name: 'objectApprovalProcesses' }"
                  role="button"
                  class="slds-button slds-button_neutral"
                  >View</router-link
                >
              </div>
            </div>
            <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout" role="grid">
              <thead>
                <tr class="slds-line-height_reset">
                  <th class="" scope="col">
                    <div class="slds-truncate" title="Name">SObject Name</div>
                  </th>
                  <th class="" scope="col">
                    <div class="slds-truncate slds-text-align_right" title="Company">Approval Processes Count</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="slds-hint-parent" v-for="entry in scan.approvalProcessCounts" :key="entry.name">
                  <th scope="row">
                    <div class="slds-truncate" :title="entry.name">
                      {{ entry.name }}
                    </div>
                  </th>
                  <td role="gridcell" class="slds-text-align_right">
                    {{ entry.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </DetailCard>
    </div>
    <div class="slds-col slds-size_1-of-4">
      <DetailCard class="slds-m-bottom_small" v-if="scan.reports && scan.reports.length > 0">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-catalog" title="Reports">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#catalog" />
            </svg>
            <span class="slds-assistive-text">Report Types</span>
          </span>
        </div>
        <div slot="title">
          <span>Report Types <span style="font-size: 10px; font-weight: 400">(Top 5)</span></span>
        </div>
        <div slot="actions">
          <router-link :to="{ name: 'reportTypes' }" role="button" class="slds-button slds-button_neutral"
            >View</router-link
          >
        </div>
        <div slot="body" class="slds-p-bottom_small">
          <table
            slot="body"
            class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
            role="grid"
          >
            <thead>
              <tr class="slds-line-height_reset">
                <th class="" scope="col">
                  <div class="slds-truncate" title="Name">Folder Name</div>
                </th>
                <th class="" scope="col">
                  <div class="slds-truncate slds-text-align_right" title="Company">Report Count</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="slds-hint-parent" v-for="entry in scan.reports" :key="entry.type">
                <th scope="row">
                  <div class="slds-truncate" :title="entry.type">
                    {{ entry.type | humanize }}
                  </div>
                </th>
                <td role="gridcell" class="slds-text-align_right">
                  {{ entry.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DetailCard>
    </div>
  </div>
</template>
<script>
import DetailCard from './DetailCard';
export default {
  props: {
    scan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {
    DetailCard,
  },
};
</script>
