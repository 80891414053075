<template>
  <Modal title="New Snapshot" @close="handleClose">
    <div slot="sub-header"></div>
    <div slot="body">
      <div class="slds-grid slds-grid_vertical">
        <div class="slds-col" v-if="showConnectToOrg">
          <center>
            <button
              class="slds-button slds-button_neutral slds-button_stretch slds-p-vertical_small"
              @click="handleConnectToOrg"
            >
              <span
                class="slds-icon_container slds-icon-utility-apex_plugin slds-current-color slds-m-right_x-small"
                title="connect"
              >
                <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                  <use
                    xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#apex_plugin"
                  />
                </svg>
                <span class="slds-assistive-text">Connect</span>
              </span>
              Connect to Org
            </button>
          </center>
        </div>
      </div>
    </div>
    <div slot="footer">
      <button class="slds-button slds-button_neutral" @click="handleClose">Cancel</button>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NewSnapshot',
  props: {
    snapshot: Object,
    showConnectToOrg: { type: Boolean, default: true },
    connectionId: { default: null },
  },
  data() {
    return {
      name: null,
    };
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
    }),
  },
  methods: {
    ...mapActions({
      rename: 'snapshots/rename',
    }),
    handleConnectToOrg() {
      this.$emit('close', { connectToOrg: true });
    },
    handleClose(saved) {
      this.$emit('close', { saved });
    },
  },
  components: {
    Modal,
  },
};
</script>
