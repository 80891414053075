<template>
  <table
    class="table slds-table slds-table_bordered slds-table_fixed-layout slds-table_resizable-cols slds-tree slds-table_tree"
    role="grid"
    v-columns-resizable
  >
    <thead>
      <tr class="slds-line-height_reset">
        <th
          v-for="column in columnsInternal"
          :key="column instanceof Object ? column.field : column"
          class="slds-is-resizable"
          scope="col"
          v-bind:style="column.field === 'id' ? { width: '4rem' } : column.width ? { width: column.width + 'px' } : ''"
        >
          <a class="slds-th__action slds-text-link_reset" role="button" tabindex="-1">
            <span class="slds-assistive-text">Sort by:</span>
            <div class="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
              <span v-bind:class="{ 'slds-truncate': column.truncate }" :title="column.title | humanize">{{
                column.title | humanize
              }}</span>
              <span class="slds-icon_container slds-icon-utility-arrowdown">
                <svg class="slds-icon slds-icon-text-default slds-is-sortable__icon" aria-hidden="true">
                  <use
                    xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#arrowdown"
                  />
                </svg>
              </span>
              <div class="sort-icon-placeholder"></div>
            </div>
          </a>
          <div class="slds-resizable">
            <input
              type="range"
              aria-label="Name column width"
              class="slds-resizable__input slds-assistive-text"
              max="1000"
              min="20"
              tabindex="0"
            />
            <span class="slds-resizable__handle">
              <span class="slds-resizable__divider"></span>
            </span>
          </div>
        </th>

        <th v-if="hasActions" scope="col" class="action-col">
          <div class="slds-truncate slds-assistive-text" title="Actions">Actions</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        aria-expanded="true"
        :aria-level="entry.level"
        aria-posinset="2"
        aria-selected="false"
        aria-setsize="2"
        class="slds-hint-parent"
        v-for="entry in tableData"
        :key="entry.id"
      >
        <template v-for="(column, index) in columnsInternal">
          <td
            role="gridcell"
            :key="column.field"
            v-bind:class="{
              'slds-cell-wrap': column.wrap,
              'slds-tree__item': index === 1,
            }"
            :data-label="entry[column.field]"
          >
            <button
              v-if="index === 1"
              class="slds-button slds-button_icon slds-button_icon-x-small slds-m-right_x-small"
              aria-hidden="true"
              tabindex="-1"
            >
              <svg class="slds-button__icon slds-button__icon_small" aria-hidden="true">
                <use
                  xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#chevronright"
                ></use></svg
              ><span class="slds-assistive-text">Collapse Acme Corporation</span>
            </button>
            <template v-if="(column.field === 'id' || column.title === 'id') && type">
              <div
                v-bind:class="{
                  'slds-truncate': column.truncate,
                  'slds-line-clamp': column.wrap,
                }"
                class="slds-has-flexi-truncate"
                :title="entry.name || entry[column.field]"
              >
                <router-link
                  :to="{
                    name: type,
                    params: { id: entry[column.field].toString() },
                    query: queryString,
                  }"
                  >{{ entry[column.field] }}</router-link
                >
              </div>
            </template>
            <template
              v-else-if="column.type && column.field && entry[column.field] && column.paramField && column.valueField"
            >
              <div
                v-bind:class="{
                  'slds-truncate': column.truncate,
                  'slds-line-clamp': column.wrap,
                }"
                class="slds-has-flexi-truncate"
                :title="entry[column.field][column.valueField]"
              >
                <router-link
                  :to="{
                    name: column.type,
                    params: {
                      id: entry[column.field][column.paramField].toString(),
                    },
                    query: queryString,
                  }"
                  >{{ entry[column.field][column.valueField] }}</router-link
                >
              </div>
            </template>
            <template v-else-if="column.type && column.field && column.paramField && column.valueField">
              <div
                v-bind:class="{
                  'slds-truncate': column.truncate,
                  'slds-line-clamp': column.wrap,
                }"
                class="slds-has-flexi-truncate"
                :title="column.valueField"
              >
                <router-link
                  :to="{
                    name: column.type,
                    params: {
                      id: entry[column.paramField].toString(),
                    },
                    query: queryString,
                  }"
                  >{{ entry[column.valueField] }}</router-link
                >
              </div>
            </template>
            <template v-else
              ><div
                v-bind:class="{
                  'slds-truncate': column.truncate,
                  'slds-line-clamp': column.wrap,
                }"
                class="slds-has-flexi-truncate"
                :title="entry[column.field]"
              >
                {{ showRelatedObject(entry, column) }}
              </div></template
            >
          </td>
        </template>
      </tr>
    </tbody>
    <tfoot>
      <slot name="footer"> </slot>
    </tfoot>
  </table>
</template>

<script>
import moment from 'moment';
import { orderBy } from 'lodash';
export default {
  props: {
    type: String,
    columns: Array,
    displayRows: Array,
    actions: Array,
    sortable: { type: Boolean, default: true },
    sortColumn: String,
    sortDesc: Boolean,
  },
  data() {
    return {
      columnsInternal: this.columns ? this.columns : this._buildColumnsFromData(),
      innerSortColumn: null,
      innerSortDesc: false,
    };
  },
  watch: {
    displayRows: function () {
      this._updateColumns();
    },
    columns: function () {
      this._updateColumns();
    },
    sortColumn: function () {
      this._updateColumns();
    },
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    hasActions() {
      return this.actions && this.actions.length > 0;
    },
    queryString() {
      return this.$route.query;
    },
    tableData() {
      if (!this.innerSortColumn) {
        return this.displayRows;
      } else if (this.innerSortColumn.valueField) {
        return orderBy(
          this.displayRows,
          [this.innerSortColumn.field + '.' + this.innerSortColumn.valueField],
          [this.innerSortDesc ? 'desc' : 'asc']
        );
      } else if (this.innerSortColumn.field) {
        return orderBy(this.displayRows, [this.innerSortColumn.field], [this.innerSortDesc ? 'desc' : 'asc']);
      } else {
        return this.displayRows;
      }
    },
  },
  methods: {
    _updateColumns() {
      if (this.columns) {
        this.columnsInternal = this.columns;
      } else {
        this.columnsInternal = this._buildColumnsFromData();
      }
    },
    _buildColumnsFromData() {
      let result = [];

      if (this.displayRows && this.displayRows.length > 0) {
        result = Object.keys(this.displayRows[0]).filter(key => !key.includes('tedAt'));
      }

      return result;
    },
    sortBy(column) {
      if (column === this.innerSortColumn) {
        this.innerSortDesc = !this.innerSortDesc;
      }
      this.innerSortColumn = column;
      this.$emit('sort', { column });
    },
    handleAction(event) {
      this.$emit(event.name, { record: event.record });
    },
    validateDate(date) {
      const allPossibleFormats = [
        'D MMMM YYYY',
        'D MMMM YYYY HH:mm',
        'DD-MM-YY',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'DD.MM.YYYY HH:mm',
        'DD/MM/YY',
        'DD/MM/YYYY',
        'DD/MM/YYYY HH:mm:ss',
        'HH:mm:ss',
        'M/D/YYYY',
        'D/M/YYYY',
        'MM-DD-YY',
        'MM-DD-YYYY',
        'MM-DD-YYYY HH:mm:ss',
        'MM/DD/YY',
        'MM/DD/YYYY',
        'MM/DD/YYYY HH:mm:ss',
        'MMM D YYYY',
        'MMM D YYYY LT',
        'MMMM Do YYYY',
        'MMMM Do YYYY LT',
        'YYYY-DD-MM HH:mm:ss',
        'YYYY-MM',
        'YYYY-MM-DD',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DD LT',
        'YYYY-MM-DD h:mm:ss A',
        'YYYY-MM-DDTHH:mm:ssZ',
        'YYYY-MM-DDTHH:mm:ss.SSSZ',
        'ddd, MMM D YYYY LT',
        'dddd D MMMM YYYY HH:mm',
        'dddd, MMMM Do YYYY LT',
      ];
      return moment(date, allPossibleFormats, true).isValid();
    },
    /**
     * If a column name is actually a reference to another sequelize model, attempt to display its name field
     * @param data
     * @returns {*}
     */
    showRelatedObject(data, column) {
      const cell = data[column.field];
      if (this.validateDate(cell)) {
        return `${moment(cell).format('MMM Do YY, h:mm a')} (${moment(cell).fromNow()})`;
      }
      if (cell instanceof Object && cell.name) {
        return cell.name;
      }

      return cell;
    },
  },
};
</script>

<style scoped>
.sort-icon-placeholder {
  display: inline-block;
  min-width: 12px;
  margin-left: 4px;
}

.slds-is-sorted .sort-icon-placeholder {
  display: none;
}

.slds-th__action:hover .sort-icon-placeholder {
  display: none;
}

.action-col {
  width: 3.25rem;
}
.slds-resizable__divider {
  z-index: auto;
}
.slds-table tfoot td,
.slds-table tfoot th,
.slds-table tfoot td,
.slds-table tfoot th {
  border-top: 1px solid #dddbda;
  background-color: #fafaf9;
}
.slds-table tfoot th,
.slds-table tbody th {
  font-weight: 700;
  color: #514f4d;
}
</style>
