<template>
  <form-group :validator="v" :label="label">
    <input v-model="name" type="text" class="slds-input" :placeholder="placeholder" @input="v.$touch()" />
  </form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
