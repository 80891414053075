<template>
  <div class="slds-dropdown-trigger slds-dropdown-trigger_click" :class="{ 'slds-is-open': isOpen }">
    <button
      class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small"
      aria-haspopup="true"
      tabindex="0"
      title="More actions"
      @click="toggle"
      @blur="close"
    >
      <svg class="slds-button__icon slds-button__icon_hint slds-button__icon_small" aria-hidden="true">
        <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
      </svg>
      <span class="slds-assistive-text">More actions</span>
    </button>
    <div class="slds-dropdown slds-dropdown_right">
      <ul class="slds-dropdown__list" role="menu" aria-label="More actions">
        <li v-for="action in actions" :key="action.name" class="slds-dropdown__item" role="presentation">
          <a v-on:mousedown.prevent="fireAction(action)" role="menuitem" :aria-disabled="action.isDisabled">
            <span class="slds-truncate" :title="action.label">{{ action.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    fireAction(action) {
      if (!action.isDisabled) {
        this.close();
        this.$emit('action', { name: action.name, record: this.record });
      }
    },
  },
};
</script>
