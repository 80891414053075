<template>
  <form novalidate>
    <Modal :title="'Salesforce Connections'" v-show="show" @close="handleClose">
      <div slot="body" v-if="!ConnectionEditVisible" class="slds-scrollable_y modal__body--max-height">
        <ol class="slds-list_vertical slds-list_vertical-space connections">
          <li class="slds-item slds-hint-parent">
            <button
              @click.prevent="handleNewConnectionToOrg"
              class="slds-filters__item slds-button slds-button_outline-brand slds-button_neutral slds-button_stretch"
            >
              <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                <use xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
              </svg>
              Setup New Connection
            </button>
          </li>
          <li class="slds-item slds-hint-parent" v-for="sfConnection in sfConnections" :key="sfConnection.id">
            <article class="slds-card connection-card">
              <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                  <div class="slds-media__figure">
                    <span
                      class="slds-icon_container"
                      title="account"
                      v-bind:class="{
                        'slds-icon-standard-default': sfConnection.isSandbox,
                        'slds-icon-standard-education': !sfConnection.isSandbox,
                      }"
                    >
                      <svg class="slds-icon slds-icon_small" aria-hidden="true">
                        <use
                          xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#data_streams"
                        ></use>
                      </svg>
                      <span class="slds-assistive-text">{{ sfConnection.name }}</span>
                    </span>
                  </div>
                  <div class="slds-media__body">
                    <h2 class="slds-card__header-title">
                      <a
                        @click="handleConnectToOrg(sfConnection.id)"
                        class="slds-card__header-link slds-truncate"
                        :title="sfConnection.name"
                      >
                        <span>
                          {{ sfConnection.name }}
                        </span>
                      </a>
                    </h2>
                  </div>
                  <div class="slds-no-flex">
                    <div class="slds-button-group" role="group">
                      <button
                        type="button"
                        class="slds-button slds-button_neutral"
                        v-if="!sfConnection.isActive"
                        @click="handleGetAuthorizarionUrl(sfConnection)"
                        :disabled="actionInProgress"
                      >
                        Connect
                      </button>
                      <button
                        type="button"
                        class="slds-button slds-button_neutral"
                        v-if="sfConnection.isActive"
                        @click="handleDisconnect(sfConnection)"
                        :disabled="actionInProgress"
                      >
                        Disconnect
                      </button>
                      <button
                        class="slds-button slds-button_neutral"
                        @click="showConnectionEdit(sfConnection)"
                        :disabled="actionInProgress"
                      >
                        Edit
                      </button>
                      <button
                        class="slds-button slds-button_destructive"
                        title="Delete"
                        @click="handleDeleteConnection(sfConnection.id)"
                        :disabled="actionInProgress"
                      >
                        <svg class="slds-button__icon" aria-hidden="true">
                          <use
                            xlink:href="~@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#delete"
                          ></use>
                        </svg>
                        <span class="slds-assistive-text">Delete</span>
                      </button>
                    </div>
                  </div>
                </header>
              </div>
              <div class="slds-card__body slds-card__body_inner">
                Created {{ sfConnection.createdAt | moment('from') }} • Org type
                {{ sfConnection.isSandbox ? 'Sandbox' : 'Production' }}
              </div>
            </article>
          </li>
        </ol>
      </div>
      <div slot="body" v-if="ConnectionEditVisible">
        <div class="slds-form">
          <InputComponent v-model="form.name" :v="$v.form.name" label="Name" placeholder="Salesforce Connection Name" />
        </div>
      </div>
      <div slot="footer" style="margin-top: 10px">
        <button
          class="slds-button slds-button_brand"
          @click.prevent="submit"
          :disabled="$v.form.$invalid"
          @click="handleConnectionEditClose"
        >
          Save
        </button>
        <button type="reset" class="slds-button slds-button_neutral" @click="handleCancel">Cancel</button>
      </div>
    </Modal>
    <DisconnectConnectionConfirm
      :show="showDisconnectConfirmationModal"
      :selectedSnapshotName="selectedSnapshotName"
      :selectedSnapshotId="selectedSnapshotId"
      @close-model="hideDisconnectConfirmationModal"
    />
  </form>
</template>

<script>
import DisconnectConnectionConfirm from '../components/DisconnectConnectionConfirm';
import InputComponent from './InputComponent.vue';
import Modal from './Modal';
import notification from '../utils/notification';
import router from '../router';

import { mapActions, mapState } from 'vuex';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';

const alphaNumAndDotValidator = helpers.regex('alphaNumAndDotValidator', /^[A-Za-z\d\-_\s\\(\\)]+$/);

export default {
  name: 'ListSFConnections',
  props: {
    sfConnections: Array,
    show: { type: Boolean, default: false },
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(80),
        alphaNumAndDotValidator,
        consecutiveWhitespace(value) {
          if (value === '') return true;

          var patt = new RegExp(/\s{2,}/);
          var res = patt.test(value);
          return !res;
        },
      },
    },
  },
  data() {
    return {
      name: null,
      sfConnectionId: null,
      ConnectionEditVisible: false,
      showDisconnectConfirmationModal: false,
      selectedSnapshotId: null,
      selectedSnapshotName: '',
      form: {
        name: '',
      },
    };
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
    }),
    id() {
      return this.sfConnections ? this.sfConnections.id : null;
    },
    disableSave(name) {
      return this.actionInProgress || name === this.name;
    },
  },
  methods: {
    ...mapActions({
      getSFConnection: 'sfConnections/get',
      deleteConnection: 'sfConnections/delete',
      edit: 'sfConnections/edit',
      getAuthorizationUrl: 'sfConnections/getAuthorizationUrl',
    }),
    handleConnectToOrg(id) {
      router.push({
        name: 'connectionSnapshots',
        params: { id },
      });
      this.$emit('close', { connectToOrg: true });
    },
    handleGetAuthorizarionUrl(conDetail) {
      let formDetail = {};
      formDetail.name = conDetail.name;
      formDetail.isReconnect = true;
      formDetail.orgId = conDetail.organizationId;
      formDetail.id = conDetail.id;
      formDetail.isSandbox = conDetail.isSandbox;
      this.getAuthorizationUrl(formDetail);
    },
    showConnectionEdit(sfConnection) {
      this.sfConnectionId = sfConnection.id;
      this.form.name = sfConnection.name;
      this.ConnectionEditVisible = true;
    },
    async handleConnectionEditClose(event) {
      this.ConnectionEditVisible = false;
      if (event.saved) {
        await this.getSFConnection(this.id);
      }
    },
    async handleDeleteConnection(id) {
      await this.deleteConnection(id);
    },
    handleNewConnectionToOrg() {
      this.$emit('close', { isNewConnectionRequest: true });
    },

    handleCancel() {
      this.ConnectionEditVisible = false;
      this.handleClose();
    },
    handleClose(saved) {
      this.ConnectionEditVisible = false;
      this.$emit('close', { saved });
    },
    async handleDisconnect(sfConnection) {
      this.handleClose();
      this.selectedSnapshotId = sfConnection.id.toString();
      this.selectedSnapshotName = sfConnection.name;
      this.showDisconnectConfirmationModal = true;
    },
    async hideDisconnectConfirmationModal() {
      this.$emit('close', { toggleView: true });
      this.showDisconnectConfirmationModal = false;
    },
    async submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      await this.edit({
        id: this.sfConnectionId,
        data: this.form,
      });
      notification.success(this, `Edit`, 'Connection edited successfully!');
      //location.reload();
      this.$emit('close', { isReloadRequired: true });
      //this.handleClose(true);
    },
  },
  components: {
    Modal,
    InputComponent,
    DisconnectConnectionConfirm,
  },
};
</script>
<style>
.modal__body--max-height {
  max-height: 35rem;
}
</style>
