<template>
  <div id="gridView">
    <DetailCard>
      <div slot="icon">
        <span class="slds-icon_container slds-icon-standard-work-type-group" title="Permission Sets">
          <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
            <use
              xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#work_type_group"
            />
          </svg>
          <span class="slds-assistive-text">Permission Sets</span>
        </span>
      </div>
      <div slot="title">
        <span>Permission Sets ({{ permissionSets.length }})</span>
      </div>
      <div slot="actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <DataSearch :items="filteredItems" :columns="columns" />
          </div>
          <div class="slds-page-header__control">
            <DataFilter :items="permissionSets" filter-field="name" title="Filter by User License" />
          </div>
        </div>
      </div>
      <div slot="body">
        <DataSort :items="searchedItems" :column="sortColumn" :desc="sortDesc" :columns="columns" />
        <DataTable
          type="item"
          :displayRows="currentPage"
          :columns="columns"
          sortable
          :isAlreadySorted="true"
          :sort-column="sortColumn"
          :sort-desc="sortDesc"
          @sort="handleSort"
        />

        <DataPaginate :per-page="100" :items="sortedItems" />
      </div>
    </DetailCard>
  </div>
</template>

<script>
import DetailCard from '../components/DetailCard';
import DataFilter from '../components/DataFilter';
import DataSearch from '../components/DataSearch';
import DataTable from '../components/DataTable';
import DataSort from '../components/DataSort';
import DataPaginate from '../components/DataPaginate';
import { mapActions, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      columns: [
        {
          title: 'id',
          field: 'itemId',
          params: {
            id: 'itemId',
          },
          width: 100,
        },
        {
          title: 'Name',
          field: 'name',
          params: {
            id: 'itemId',
          },
          type: 'item',
          wrap: true,
          width: 150,
        },
        {
          title: 'userLicense',
          field: 'userLicense',
          width: 100,
          wrap: true,
        },
        {
          title: 'description',
          field: 'description',
          width: 150,
          wrap: true,
        },
        {
          title: 'editReadonlyFields',
          field: 'editReadonlyFields',
          width: 70,
        },
        {
          title: 'manageLoginAccessPolicies',
          field: 'manageLoginAccessPolicies',
          width: 70,
        },
        {
          title: 'managePasswordPolicies',
          field: 'managePasswordPolicies',
          width: 70,
        },
        {
          title: 'manageProfilesPermissionsets',
          field: 'manageProfilesPermissionsets',
          width: 70,
        },
        {
          title: 'manageRoles',
          field: 'manageRoles',
          width: 70,
        },
        {
          title: 'manageSharing',
          field: 'manageSharing',
          width: 70,
        },
        {
          title: 'manageUsers',
          field: 'manageUsers',
          width: 70,
        },
        {
          title: 'modifyAllData',
          field: 'modifyAllData',
          width: 70,
        },
        {
          title: 'resetPasswords',
          field: 'resetPasswords',
          width: 70,
        },
        {
          title: 'viewAllData',
          field: 'viewAllData',
          width: 70,
        },
        {
          title: 'exportReport',
          field: 'exportReport',
          width: 70,
        },
        {
          title: 'dataExport',
          field: 'dataExport',
          width: 70,
        },
        {
          title: 'modifyAllPermission',
          field: 'modifyAllPermission',
          width: 70,
        },
        { title: 'User Count', field: 'count', width: 100 },
      ],
      sortColumn: 'id',
      sortDesc: false,
      currentRecord: null,
      type: 'PermissionSet',
    };
  },
  async mounted() {
    await this.getScan({ id: this.id });
    await this.getAll(this.id);
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
      permissionSets: state => state.permissionSets.all,
      filteredItems: state => state.dataFilter.data,
      searchedItems: state => state.dataSearch.data,
      sortedItems: state => state.dataSort.data,
      currentPage: state => state.dataPaginate.data,
    }),
    data() {
      return this.permissionSets;
    },
  },
  methods: {
    ...mapActions({
      getScan: 'scans/get',
    }),
    ...mapCacheActions('permissionSets', ['getAll']),
    handleSort(event) {
      if (event.column.field === this.sortColumn) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = event.column.field;
        this.sortDesc = false;
      }
    },
  },
  components: {
    DataFilter,
    DataSearch,
    DataSort,
    DataTable,
    DataPaginate,
    DetailCard,
  },
};
</script>
