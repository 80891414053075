<template>
  <div class="slds-grid slds-gutters slds-wrap">
    <div class="slds-col slds-size_1-of-2">
      <DetailCard class="slds-m-bottom_small">
        <div slot="icon">
          <span class="slds-icon_container slds-icon-standard-feed" title="scan item">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="~@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg#feed" />
            </svg>
            <span class="slds-assistive-text">scan item</span>
          </span>
        </div>
        <div slot="title">
          <span>Scan Log</span>
        </div>
        <div slot="body" class="slds-card__body_inner slds-p-bottom_small">
          <pre class="slds-text-font_monospace slds-text-body_small" v-if="scan && scan.log">{{ scan.log }}</pre>
        </div>
      </DetailCard>
    </div>
  </div>
</template>
<script>
import DetailCard from './DetailCard';
export default {
  props: {
    scan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {
    DetailCard,
  },
};
</script>
