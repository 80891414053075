<template>
  <form novalidate>
    <Modal :title="'Setup New Connection'" v-show="show" @close="handleClose">
      <div slot="sub-header"></div>
      <div slot="body">
        <div class="slds-form">
          <InputComponent v-model="form.name" :v="$v.form.name" label="Name" placeholder="Salesforce Connection Name" />
          <fieldset class="slds-form-element slds-form-element_stacked">
            <div class="slds-form-element__control">
              <div class="slds-checkbox">
                <input v-model="form.isSandbox" type="checkbox" name="isSandbox" id="isSandbox" />
                <label class="slds-checkbox__label" for="isSandbox">
                  <span class="slds-checkbox_faux"></span>
                  <span class="slds-form-element__label">Is Sandbox</span>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div slot="footer">
        <button @click.prevent="submit" :disabled="$v.form.$invalid" class="slds-button slds-button_brand">
          Connect
        </button>
        <button type="reset" class="slds-button slds-button_neutral" @click="handleCancel">Close</button>
      </div>
    </Modal>
  </form>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import InputComponent from './InputComponent.vue';
import Modal from './Modal';

import { mapState, mapActions } from 'vuex';
export default {
  name: 'NewSFConnection',
  props: {
    snapshot: Object,
    show: { type: Boolean, default: false },
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(80),
        consecutiveWhitespace(value) {
          if (value === '') {
            return true;
          }
          const patt = new RegExp(/\s{2,}/);
          const res = patt.test(value);
          return !res;
        },
      },
    },
  },
  data() {
    return {
      form: {
        name: '',
        isSandbox: false,
      },
    };
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      actionInProgress: state => state.app.actionInProgress,
    }),
  },
  methods: {
    ...mapActions({
      getAuthorizationUrl: 'sfConnections/getAuthorizationUrl',
    }),
    async submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      await this.getAuthorizationUrl(this.form);
      this.form = {
        name: '',
        isSandbox: false,
      };
      this.handleClose();
    },
    handleCancel() {
      this.handleClose();
    },
    handleClose(saved) {
      this.$emit('close', { saved });
    },
  },
  components: {
    Modal,
    InputComponent,
  },
};
</script>
