import Vue from 'vue';
import moment from 'vue-moment';
import Vuelidate from 'vuelidate';
import VuelidateErrorExtractor from 'vuelidate-error-extractor';
import Notifications from 'vt-notifications';
import VueColumnsResizable from 'vue-columns-resizable';
import 'tailwindcss/dist/tailwind.css';

import App from './App.vue';
import store from './store';
import router from './router';
import formGroup from './components/formGroup.vue';
import { Auth0Plugin } from './auth';
import getEnv from '@/utils/getEnv';

Vue.use(moment);
Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VueColumnsResizable);
Vue.use(VuelidateErrorExtractor, {
  i18n: false,
  template: formGroup,
  // Define common validation messages.
  messages: {
    required: '{attribute} is required!',
    minLength: '{attribute} should contain at least {min} character(s)!',
    maxLength: '{attribute} cannot be greater than {max} character(s)!',
    email: '{attribute} is not a valid Email address.',
    isValidUrl: '{attribute} is not a valid Url',
    isEmailAvailable: '{attribute} is not available. Must be at least 10 characters long.',
    alphaNumAndDotValidator: 'Valid characters are A-Z a-z 0-9 with single space in middle',
    consecutiveWhitespace: 'Cannot contain more than one consecutive whitespace character',
  },
});

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  domain: getEnv('VUE_APP_AUTH0_DOMAIN'),
  clientId: getEnv('VUE_APP_AUTH0_CLIENT_ID'),
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: getEnv('VUE_APP_AUTH0_AUDIENCE'),
  },
  onRedirectCallback: appState =>
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.origin),
});

Vue.filter('readableDate', input => {
  return input ? new Date(input).toLocaleDateString() : null;
});

Vue.filter('humanize', input => {
  if (input) {
    const filtered = input.replace(/([A-Z])/g, ' $1').trim();
    return filtered.charAt(0).toUpperCase() + filtered.slice(1);
  }

  return null;
});

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
});
