import api from '../../utils/api';
import { cacheAction } from 'vuex-cache';

const state = () => ({
  all: [],
});

const getters = {};

const actions = {
  getAll: cacheAction(async ({ commit, rootState }, scanId) => {
    commit('app/setActionInProgress', null, { root: true });
    try {
      const items = await api.get({
        path: `/scans/${scanId}/orgLimitStatuses`,
      });
      commit('setAll', items);
    } catch (error) {
      commit('app/setError', error, { root: true });
    }
    commit('app/clearActionInProgress', null, { root: true });
  }),
};

const mutations = {
  setAll(state, all) {
    state.all = all;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
