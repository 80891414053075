<template>
  <div class="slds-form-element slds-form-element_stacked" :class="{ error: hasErrors }">
    <label class="slds-form-element__label" :for="label">{{ label }} </label>
    <div class="slds-form-element__control">
      <slot />
      <div class="errors" v-if="hasErrors">
        <div class="error-message" v-for="error in activeErrorMessages" :key="error">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  mixins: [singleErrorExtractorMixin],
};
</script>
