var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slds-grid slds-gutters slds-wrap slds-grid_vertical-stretch",staticStyle:{"margin-bottom":"15px"}},_vm._l((_vm.systemOverviewsGroups),function(entry){return _c('div',{key:entry,staticClass:"panel-container slds-col slds-size_1-of-2"},[_c('span',[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"top-line"},[_c('h2',[_vm._v(_vm._s(entry))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"panelContent"},_vm._l((_vm.groupedSystemOverviews[entry]),function(entryItem,index){return _c('div',{key:entryItem.id,staticClass:"panel-content-item",class:{
                'border-top': index !== 0,
                'usage-warn': entryItem.isWarning,
              }},[_c('div',{staticClass:"panelLeft"},[_c('div',{staticClass:"type"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(entryItem.name))])]),_c('div',{staticClass:"datalink"},[_c('div',{staticClass:"num",attrs:{"id":"usage_block_schema_num_1"}},[_vm._v(" "+_vm._s(entryItem.value)+" "),_c('span',{staticClass:"valueSubscript"})])]),_c('div',{staticClass:"floatClear"})]),_c('div',{staticClass:"panelRight"},[_c('div',{staticClass:"visual"},[(entryItem.percentage !== null)?_c('div',{staticClass:"slds-progress-bar progress-bar",attrs:{"aria-valuemin":"0","aria-valuemax":"100","aria-valuenow":entryItem.percentage,"role":"progressbar"}},[_c('span',{staticClass:"slds-progress-bar__value",class:{
                        'slds-progress-bar__value_success': !entryItem.isWarning,
                        'slds-progress-bar__value_error': entryItem.isWarning,
                      },style:({
                        width: (entryItem.percentage > 100 ? 100 : entryItem.percentage) + '%',
                      })},[_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Progress: "+_vm._s(entryItem.percentage)+"%")])])]):_vm._e()]),_c('span',[_c('div',{staticClass:"desc",attrs:{"align":"right"}},[_c('span',{staticClass:"desc-num"},[_vm._v(_vm._s(entryItem.message))]),_c('br')])])]),_c('div',{staticClass:"floatClear"})])}),0)])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }